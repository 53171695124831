import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TendersTabs } from 'src/constants/routes'

import { fetchAllTendersIds, fetchTenders } from './actions'
import { TInitialState } from './types'

const initialState: TInitialState = {
  prevTab: null,
  error: null,
  isFetching: false,
  data: [],
  selectedIds: [],
  number: 0,
  totalElements: 0,
}

const tenders = createSlice({
  name: 'tenders',
  initialState,
  reducers: {
    resetTenders: () => initialState,
    setPrevTab: (state, action: PayloadAction<TendersTabs>) => {
      state.prevTab = action.payload
    },
    setSelectedIds: (state, { payload }) => ({
      ...state,
      selectedIds: payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchTenders.pending, state => {
      state.isFetching = true
    })
    builder.addCase(fetchTenders.fulfilled, (state, { payload }) => {
      state.data = payload.content
      state.totalElements = payload.totalElements
      state.number = payload.number
      state.isFetching = false
    })
    builder.addCase(fetchTenders.rejected, state => {
      state.error = true
      state.isFetching = false
    })
    builder.addCase(fetchAllTendersIds.fulfilled, (state, { payload }) => {
      state.selectedIds = payload
    })
  },
})

export const { resetTenders, setSelectedIds, setPrevTab } = tenders.actions

export default tenders.reducer
