import React from 'react'
import { Menu, List } from '@material-ui/core'

import './catalog.scss'

export type CatalogMenuProps = {
  anchorMenuEl: null | HTMLElement
  handleCloseMenu: () => void
}

export const CatalogMenu: React.FC<CatalogMenuProps> = ({
  anchorMenuEl,
  handleCloseMenu,
  children,
}) => (
  <Menu
    className='catalog-menu'
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    anchorEl={anchorMenuEl}
    open={Boolean(anchorMenuEl)}
    onClose={handleCloseMenu}
  >
    <List>{children}</List>
  </Menu>
)
