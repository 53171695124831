import { AxiosPromise, AxiosResponse } from 'axios'

export function normalizeResponse<T>(
  promise: AxiosPromise<T>,
): Promise<[Error, AxiosResponse<T, any>]> {
  return promise
    .then((data: AxiosResponse<T, any>) => [null, data])
    .catch(err => [err, null]) as Promise<[Error, AxiosResponse<T, any>]>
}

export const downloadFile = (response: AxiosResponse) => {
  const contentDisposition = response.headers['content-disposition']
  // eslint-disable-next-line require-unicode-regexp
  const [, filename] = String(contentDisposition).split(/[:;,]/)
  const decodeFilename = decodeURI(filename)
  const normalizeFilename = decodeFilename.slice(18)

  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'xlsx' }),
  )

  const link = document.createElement('a')
  link.setAttribute('download', `${normalizeFilename}`)
  link.href = url

  link.click()

  URL.revokeObjectURL(link.href)
}
