import React from 'react'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'

import './date-picker.scss'

type Props = Omit<KeyboardDatePickerProps, 'error'> & {
  error?: string | boolean
}

export const DatePicker: React.FC<Props> = ({ error, ...props }) => (
  <KeyboardDatePicker
    className='mailer-date-picker'
    disableToolbar
    variant='inline'
    inputVariant='outlined'
    format='dd/MM/yyyy'
    margin='none'
    value={null}
    error={!!error}
    helperText={typeof error === 'string' ? error : ''}
    autoOk={true}
    {...props}
  />
)
