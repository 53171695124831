import React, { useCallback, useState } from 'react'
import { IconButton, Menu, MenuItem, Checkbox } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'

import { HeadCell } from './extended-table'

import './extended-table.scss'

type Props = {
  columns: HeadCell[]
  onSettingsChange: (columns: HeadCell[]) => void
}

export const HeaderSettings: React.FC<Props> = ({
  columns,
  onSettingsChange,
}) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }
  const handleClose = () => {
    setMenuAnchor(null)
  }
  const onItemClick = useCallback(
    id => {
      onSettingsChange(
        columns.map(item => ({
          ...item,
          isHidden: item.id === id ? !item.isHidden : item.isHidden,
        })),
      )
    },
    [columns, onSettingsChange],
  )

  return (
    <>
      <IconButton className='settings-button' onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        className='table-menu'
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem className='menu-item' disabled={true}>
          Список колонок
        </MenuItem>
        {columns.map(({ id, label, isHidden, required }) => {
          if (!label) {
            return null
          }

          return (
            <MenuItem
              key={`${id}-${label}`}
              onClick={() => onItemClick(id)}
              className='menu-item'
              disabled={required}
            >
              <Checkbox checked={!isHidden} color='primary' />
              {label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
