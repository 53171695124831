import React from 'react'
import { Tooltip } from '@material-ui/core'
import { GridColumnHeaderParams } from '@material-ui/x-grid'

import './tableHeader.scss'

type TTableHeader = {
  headerName: string
  params?: GridColumnHeaderParams
  className?: string
}

export const TableHeader: React.FC<TTableHeader> = ({
  headerName,
  className,
  params,
}) => (
  <div>
    <Tooltip title={headerName}>
      <div className='headerCell'>{headerName}</div>
    </Tooltip>
  </div>
)
