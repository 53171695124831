import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
  MiddlewareAPI,
  Dispatch,
  AnyAction,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { reducer as toastrReducer } from 'react-redux-toastr'

import tenderReducer from './tender/tender-slice'
import providersReducer from './providers/providers-slice'
import tendersReducer from './tenders/tenders-slice'
import tenderDetailReducer from './tender-detail/tender-detail-slice'
import userReducer from './user/user-slice'
import { resolveExceptionError } from './error/resolveExceptionError'
import { maintenance } from './maintenance/maintenance.slice'

// TODO move to application start
const SHOW_VALIDATION_ERRORS = true

const errorInterceptor =
  ({ dispatch }: MiddlewareAPI<Dispatch<any>, any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload as Error

      dispatch(
        resolveExceptionError({
          error: payload,
          showValidationErr: SHOW_VALIDATION_ERRORS,
        }),
      )
    }
    return next(action)
  }

export const store = configureStore({
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['@ReduxToastr/toastr/ADD', '@ReduxToastr/toastr/REMOVE'],
    },
  }).concat(errorInterceptor),
  reducer: {
    tenders: tendersReducer,
    tender: tenderReducer,
    tenderDetail: tenderDetailReducer,
    providers: providersReducer,
    user: userReducer,
    toastr: toastrReducer,
    maintenance,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
