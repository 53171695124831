import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { isNil } from 'lodash'
import DateFnsAdapter from '@date-io/date-fns'

import {
  GridCellParams,
  GridSortModel,
  GridColumnHeaderParams,
} from '@material-ui/x-grid'

import { ModuleTypes, TypeTenders } from 'src/types/api'

import { AppRoutes, TendersTabs } from 'src/constants/routes'

import { ReactComponent as UserIcon } from 'src/assets/svg/user.svg'
import { ReactComponent as FileIcon } from 'src/assets/svg/file.svg'

import { TableHeader } from '../../components/table-header/tableHeader'

import { MODULE_TYPES } from './constants'

import './tenders.scss'

const dateFns = new DateFnsAdapter()

export const createInitSortModel = (type: TypeTenders): GridSortModel => {
  if (type === TendersTabs.incoming) {
    return [
      {
        field: 'sendDateTime',
        sort: 'desc',
      },
    ]
  }

  return [
    {
      field: 'sendDateTime',
      sort: 'desc',
    },
  ]
}

export const createGridTableColumns = (type: TypeTenders) => {
  if (type === TendersTabs.incoming) {
    return [
      {
        field: 'tenderTaskNumber',
        headerName: '№ ',
        width: 170,
        required: true,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => (
          <TableHeader headerName='№ ' params={params} />
        ),
      },
      {
        field: 'subject',
        headerName: 'Название ТЗ',
        width: 200,
        required: true,
        flex: 1.5,
        renderCell: ({ row }: GridCellParams) => (
          <Link
            className='tableLink'
            to={generatePath(AppRoutes.tenderDetail, {
              tab: type,
              id: row.id,
            })}
          >
            {row.subject}
          </Link>
        ),
        renderHeader: (params: GridColumnHeaderParams) => (
          <TableHeader headerName='Название ТЗ' params={params} />
        ),
      },
      {
        field: 'lotName',
        headerName: 'Название лота',
        width: 200,
        flex: 1.5,
        renderCell: ({ row }: GridCellParams) =>
          isNil(row.lotName) ? (
            ''
          ) : (
            <div title={row.lotName}>{row.lotName}</div>
          ),
        renderHeader: (params: GridColumnHeaderParams) => (
          <TableHeader headerName='Название лота' params={params} />
        ),
      },
      {
        field: 'module',
        headerName: 'Модуль',
        width: 170,
        required: true,
        flex: 1,
        renderCell: ({ row }: GridCellParams) => {
          const module = MODULE_TYPES[row.module as ModuleTypes]
          return isNil(row.module) ? '' : <div title={module}>{module}</div>
        },
        renderHeader: (params: GridColumnHeaderParams) => (
          <TableHeader headerName='Название лота' params={params} />
        ),
      },
      {
        field: 'specializations',
        headerName: 'Специализация',
        width: 200,
        flex: 1.5,
        renderCell: ({ row }: GridCellParams) => (
          <div title={row.specializations.join(', ')}>
            {row.specializations.join(', ')}
          </div>
        ),
        renderHeader: (params: GridColumnHeaderParams) => (
          <TableHeader headerName='Специализация' params={params} />
        ),
      },
      {
        field: 'sendDateTime',
        headerName: 'Дата',
        width: 170,
        flex: 1.3,
        renderCell: ({ row }: GridCellParams) => {
          if (isNil(row.sendDateTime)) {
            return ''
          }
          const initialDateFnsDate = dateFns.date(row.sendDateTime)
          const date = dateFns.format(initialDateFnsDate, 'dd.MM.YYY kk:mm')
          return <div title={date}>{date}</div>
        },
        renderHeader: (params: GridColumnHeaderParams) => (
          <TableHeader headerName='Дата' params={params} />
        ),
      },
      {
        field: 'countOfAnswers',
        headerName: 'Поставщики',
        width: 170,
        sortable: false,
        flex: 1,
        renderCell: ({ row }: GridCellParams) => (
          <div title='Количество ответов' className='icon-column'>
            <UserIcon /> {row.countOfAnswers}
          </div>
        ),
        renderHeader: (params: GridColumnHeaderParams) => (
          <TableHeader headerName='Поставщики' params={params} />
        ),
      },
    ]
  }

  return [
    {
      field: 'tenderTaskNumber',
      headerName: '№ ',
      width: 170,
      required: true,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='№ ' params={params} />
      ),
    },
    {
      field: 'subject',
      headerName: 'Название ТЗ',
      width: 200,
      required: true,
      flex: 1.5,
      renderCell: ({ row }: GridCellParams) =>
        isNil(row.module) || isNil(row.id) ? (
          row.subject
        ) : (
          <Link
            className='tableLink'
            title={String(row.subject)}
            to={generatePath(AppRoutes.tendersModal, {
              tab: type,
              source: row.module,
              id: row.id,
              type: 'outgoingMail',
            })}
          >
            {row.subject}
          </Link>
        ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='Название ТЗ' params={params} />
      ),
    },
    {
      field: 'lotName',
      headerName: 'Название лота',
      width: 200,
      flex: 1.5,
      renderCell: ({ row }: GridCellParams) =>
        isNil(row.lotName) ? '' : <div title={row.lotName}>{row.lotName}</div>,
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='Название лота' params={params} />
      ),
    },
    {
      field: 'module',
      headerName: 'Модуль',
      width: 170,
      required: true,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => {
        const module = MODULE_TYPES[row.module as ModuleTypes]

        return isNil(row.module) ? '' : <div title={module}>{module}</div>
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='Модуль' params={params} />
      ),
    },
    {
      field: 'specializations',
      headerName: 'Специализация',
      width: 200,
      flex: 1.5,
      renderCell: ({ row }: GridCellParams) => (
        <div title={row.specializations.join(', ')}>
          {row.specializations.join(', ')}
        </div>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='Специализация' params={params} />
      ),
    },
    {
      field: 'sendDateTime',
      headerName: 'Дата',
      width: 170,
      flex: 1.3,
      renderCell: ({ row }: GridCellParams) => {
        if (isNil(row.sendDateTime)) {
          return ''
        }
        const initialDateFnsDate = dateFns.date(row.sendDateTime)
        const date = dateFns.format(initialDateFnsDate, 'dd.MM.YYY kk:mm')
        return <div title={date}>{date}</div>
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='Дата' params={params} />
      ),
    },
    {
      field: 'countOfProviders',
      headerName: 'Поставщики',
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => (
        <div title='Почта' className='icon-column'>
          <UserIcon /> {row.countOfProviders}
        </div>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='Поставщики' params={params} />
      ),
    },
    {
      field: 'countOfAttachments',
      headerName: 'Документы',
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => (
        <div title='Файлы' className='icon-column'>
          <FileIcon /> {row.countOfAttachments}
        </div>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <TableHeader headerName='Документы' params={params} />
      ),
    },
  ]
}
