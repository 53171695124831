import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { Key } from 'src/types/common'

import { ExtendedTableHeader } from './extended-table-header'
import { ExtendedTableBody } from './extended-table-body'
import { setLocalTableSettings, getColumns, getRowsPerPage } from './utils'

import './extended-table.scss'

export type TOrder = 'asc' | 'desc'

export type HeadCell = {
  disablePadding?: boolean
  id: string
  label: string
  isHidden?: boolean
  required?: boolean
  /**
   * Only one of all columns can be with sticky option
   */
  sticky?: boolean
}

export type TRow = {
  id: Key
  subject?: string | React.ReactNode
  // eslint-disable-next-line
    [key: string]: any,
}

export type TableParams = {
  page: number
  size: number
  sort?: {
    sortBy?: string
    direction?: 'asc' | 'desc'
  }
}

export type TExtendedTable = {
  headCells: HeadCell[]
  rows: TRow[]
  rowsPerPageOptions: number[]
  beginRowsPerPage: number
  startPage?: number
  totalElements: number
  initTypeSort?: TOrder
  defaultSortBy?: string
  withSettings?: boolean
  showSelectedCounter?: boolean
  /**
   * Уникальный ключ для сохранения настроек отображения колонок в local storage
   */
  localStorageKey?: string
  /**
   * Выбранные строки таблицы по id
   * */
  selected?: Array<Key>
  handleSetChosen: (data: Key[]) => void
  onSelectAll?: () => void
  onParametersChange?: (params: TableParams) => void
}

export const ExtendedTable: React.FC<TExtendedTable> = React.memo(
  ({
    rows,
    headCells,
    rowsPerPageOptions,
    beginRowsPerPage,
    startPage = 0,
    initTypeSort = 'asc',
    defaultSortBy,
    withSettings,
    showSelectedCounter = true,
    localStorageKey,
    selected = [],
    totalElements,
    onSelectAll,
    handleSetChosen,
    onParametersChange,
  }) => {
    const [headerColumns, setHeaderColumns] = React.useState(
      getColumns(headCells, withSettings, localStorageKey),
    )
    const [rowsPerPage, setRowsPerPage] = React.useState(
      getRowsPerPage(beginRowsPerPage, withSettings, localStorageKey),
    )

    const [order, setOrder] = React.useState<TOrder>(initTypeSort)
    const [orderBy, setOrderBy] = React.useState(defaultSortBy)

    /** Пагинация */
    const [page, setPage] = React.useState(startPage)

    /** Обработчик сортировки */
    const createSortHandler =
      (sortBy: string) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === sortBy && order === 'asc'
        const direction = isAsc ? 'desc' : 'asc'

        if (onParametersChange) {
          onParametersChange({
            page: startPage,
            size: rowsPerPage,
            sort: {
              sortBy,
              direction,
            },
          })
        }
        setOrder(direction)
        setOrderBy(sortBy)
      }

    const handleChangePage = (event: unknown, newPage: number) => {
      if (onParametersChange) {
        onParametersChange({
          page: newPage,
          size: rowsPerPage,
          sort: {
            sortBy: orderBy,
            direction: (orderBy && order) as TOrder,
          },
        })
      }
      setPage(newPage)
    }

    /** Обработчик показа количества на странице */
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const parsedValue = parseInt(event.target.value, 10)
      if (onParametersChange) {
        onParametersChange({
          page: startPage,
          size: parsedValue,
          sort: {
            sortBy: orderBy,
            direction: (orderBy && order) as TOrder,
          },
        })
      }

      if (localStorageKey) {
        setLocalTableSettings(localStorageKey, { rowsPerPage: parsedValue })
      }
      setRowsPerPage(parsedValue)
      setPage(0)
    }

    const onSettingsChangeHandler = (columns: HeadCell[]) => {
      setHeaderColumns(columns)

      if (localStorageKey) {
        setLocalTableSettings(localStorageKey, { columns })
      }
    }

    const setSelectedHandler = (selected: Key[]) => {
      handleSetChosen(selected)
    }

    useEffect(() => {
      if (onParametersChange) {
        onParametersChange({
          page: startPage,
          size: rowsPerPage,
          sort: {
            sortBy: orderBy,
            direction: (orderBy && order) as TOrder,
          },
        })
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Paper className='root-table'>
        <TableContainer className='table-container'>
          <Table stickyHeader className='extended-table'>
            <ExtendedTableHeader
              headerColumns={headerColumns}
              rows={rows}
              selected={selected}
              createSortHandler={createSortHandler}
              setSelected={setSelectedHandler}
              onSettingsChange={onSettingsChangeHandler}
              onSelectAll={onSelectAll}
              order={order}
              orderBy={orderBy}
              withSettings={withSettings}
            />
            <ExtendedTableBody
              selected={selected}
              rows={rows}
              setSelected={setSelectedHandler}
              headerColumns={headerColumns}
              withSettings={withSettings}
            />
          </Table>
        </TableContainer>
        <TablePagination
          className='extended-table__pagination'
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `больше чем${to}`}`
          }
          labelRowsPerPage='Строк на странице:'
          rowsPerPageOptions={rowsPerPageOptions}
          component={({ children, className }) => (
            <div className={className}>
              <div>
                {showSelectedCounter ? `Выбрано: ${selected.length}` : null}
              </div>
              {children}
            </div>
          )}
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    )
  },
)

ExtendedTable.displayName = 'ExtendedTable'
