/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react'

export const useDebouncedCallback = (
  func: (...args: any[]) => any,
  delay: number,
  deps: any[],
) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const debouncedFunction = useCallback(
    (...args: any) => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        func(...args)
        timeout.current = null
      }, delay)
    },
    [...deps, func, delay],
  )

  return debouncedFunction
}
