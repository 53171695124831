import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'
import { get } from 'lodash'

import { Provider, TenderData, UploadedFile, RejectMail } from 'src/types/api'
import { Key } from 'src/types/common'

import { apiClient, ApiResponse } from 'src/services/ApiService'
import { TenderSources, SendTendersType } from 'src/constants/routes'

export const getTender = createAsyncThunk<
  TenderData,
  { source: TenderSources; id: Key; type: SendTendersType }
>('getTender', async ({ source, id, type }, { rejectWithValue }) => {
  let result: ApiResponse<TenderData>
  switch (type) {
    case SendTendersType.sendTz:
      result = await apiClient.get<TenderData>({
        path: `/publication/${source}/tender-task/${id}`,
      })
      break

    case SendTendersType.outgoingMail:
      result = await apiClient.get<TenderData>({
        path: `/outgoing_mail/${id}`,
      })
      break

    case SendTendersType.sendLotFromMailer:
      result = await apiClient.get<TenderData>({
        path: `/outgoing_mail/to-send/${id}`,
      })
      break

    default:
      result = await apiClient.get<TenderData>({
        path: `/publication/${source}/${id}`,
      })
  }

  const [err, res] = result

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data
})

export const sendTender = createAsyncThunk<unknown, TenderData>(
  'sendTender',
  async (tender, { rejectWithValue }) => {
    const [err, result] = await apiClient.post({
      path: '/outgoing_mail/send',
      body: tender,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success(
      'Сообщение отправлено',
      `Рассылка лота поставщикам прошла успешно.
            Данный лот можно найти в разделе "Отправленные"`,
    )

    return result.data
  },
)

export const sendTenderTz = createAsyncThunk<unknown, TenderData>(
  'sendTender',
  async (tender, { rejectWithValue }) => {
    const [err, result] = await apiClient.post({
      path: '/outgoing_mail/send-tender-task',
      body: tender,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success(
      'Сообщение отправлено',
      `Рассылка лота поставщикам прошла успешно.
            Данный лот можно найти в разделе "Отправленные"`,
    )

    return result.data
  },
)

export const saveTender = createAsyncThunk<unknown, TenderData>(
  'saveTender',
  async (tender, { rejectWithValue }) => {
    const [err, result] = await apiClient.post({
      path: '/outgoing_mail/save-tender-task',
      body: tender,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success(
      'Сообщение не отправлено',
      `Рассылка лота поставщикам не прошла. 
            Данный лот можно найти в разделе "Новые"`,
    )

    return result.data
  },
)

export const saveTenderTz = createAsyncThunk<unknown, TenderData>(
  'saveTenderTz',
  async (tender, { rejectWithValue }) => {
    const [err, result] = await apiClient.post({
      path: '/outgoing_mail/save',
      body: tender,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success(
      'Сообщение не отправлено',
      `Рассылка лота поставщикам не прошла. 
            Данный лот можно найти в разделе "Новые"`,
    )

    return result.data
  },
)

export const rejectMail = createAsyncThunk<unknown, RejectMail>(
  'rejectMailSent',
  async (data, { rejectWithValue }) => {
    const [err, result] = await apiClient.post({
      path: '/outgoing_mail/cancel',
      body: data,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('Сообщение отменено', 'Рассылка отменена')

    return result.data
  },
)

export const uploadFiles = createAsyncThunk<UploadedFile[], File[]>(
  'uploadFiles',
  async (files, { rejectWithValue }) => {
    const formData = new FormData()
    files.forEach(file => formData.append('files', file))
    const [err, result] = await apiClient.post<any, UploadedFile[]>({
      path: '/attachment',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    let { data } = result
    const status = get(result.data, 'status')
    if (status === 'error') {
      data = []
    }
    return data
  },
)

export const uploadFilesLots = createAsyncThunk<
  UploadedFile[],
  { fl: File[]; lotArrayIndex: number }
>('uploadFilesLots', async ({ fl }, { rejectWithValue }) => {
  const formData = new FormData()
  fl.forEach(file => formData.append('files', file))
  const [err, result] = await apiClient.post<any, UploadedFile[]>({
    path: '/attachment',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  if (err) {
    throw rejectWithValue(err)
  }

  let { data } = result

  const status = get(result.data, 'status')
  if (status === 'error') {
    data = []
  }
  return data
})

export const getProvidersByIds = createAsyncThunk<Provider[], Key[]>(
  'getProvidersByIds',
  async (ids, { rejectWithValue }) => {
    const [err, result] = await apiClient.post<any, Provider[]>({
      path: '/provider/get',
      body: ids,
    })

    if (err) {
      throw rejectWithValue(err)
    }
    return result.data
  },
)

export const getProvidersByIdsLots = createAsyncThunk<
  Provider[],
  { ids: Key[]; lotArrayIndex: number }
>('getProvidersByIdsLots', async ({ ids }, { rejectWithValue }) => {
  const [err, result] = await apiClient.post<any, Provider[]>({
    path: '/provider/get',
    body: ids,
  })

  if (err) {
    throw rejectWithValue(err)
  }
  return result.data
})
