import { map, some } from 'lodash'
import { isBeforeDay } from 'src/utils/date'
import { E_MAIL_REG_EXP } from 'src/constants/regularExpressions'
import { SendTendersType } from 'src/constants/routes'
import {
  FIELD_TEXT_ERROR,
  EMAIL_INPUT_ERROR,
  DATE_LESS_CURRENT_ERROR,
  DATE_LESS_START_ERROR,
} from 'src/constants/text'
import { TenderData, Lot, Provider } from 'src/types/api'

const lotsValidate = (lots: Lot[]) => {
  const checkLots = map(lots, i => i.providers.length > 0)

  return some(checkLots, i => i === false) ? checkLots : false
}

export const validateProvidersByLot = (
  isShowError: boolean,
  providersByLot: boolean | string,
  checkLength: Provider[],
) => (isShowError && !(checkLength.length > 0) ? providersByLot : false)

export const validation = (tender: TenderData, type: string, lots?: Lot[]) => ({
  tenderType: !tender.tenderType?.length && FIELD_TEXT_ERROR,
  providers:
    SendTendersType.sendTz === type
      ? false
      : !tender.providers?.length && FIELD_TEXT_ERROR,
  providersByLot:
    SendTendersType.sendLot === type
      ? false
      : lotsValidate(lots || []) && FIELD_TEXT_ERROR,
  dateStart:
    (!tender.dateStart && FIELD_TEXT_ERROR) ||
    (isBeforeDay(tender.dateStart, new Date()) && DATE_LESS_CURRENT_ERROR),
  dateEnd:
    (!tender.dateEnd && FIELD_TEXT_ERROR) ||
    (isBeforeDay(tender.dateEnd, tender.dateStart) && DATE_LESS_START_ERROR),
  customer: !tender.customer && FIELD_TEXT_ERROR,
  contacts: !tender.contacts && FIELD_TEXT_ERROR,
  email:
    (!tender.email && FIELD_TEXT_ERROR) ||
    (!E_MAIL_REG_EXP.test(tender.email || '') && EMAIL_INPUT_ERROR),
})

export const getModalTitle = (type: string): string => {
  let title

  switch (type) {
    case 'outgoingMail':
      title = 'Тендерное задание'
      break

    case 'sendLot':
      title = 'Отправить Лот'
      break

    default:
      title = 'Отправить Тендерное задание'
  }

  return title
}
