import React, { useCallback, useEffect, useState } from 'react'
import { difference, without, uniq } from 'lodash'
import classNames from 'classnames'
import {
  TableHead,
  Menu,
  MenuItem,
  Checkbox,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Key } from 'src/types/common'

import { HeadCell, TOrder, TRow } from './extended-table'
import { HeaderSettings } from './extended-table-header-settings'

type TExtendedTableHeader = {
  headerColumns: HeadCell[]
  rows: TRow[]
  selected: Key[]
  createSortHandler: (
    property: string,
  ) => (event: React.MouseEvent<unknown>) => void
  setSelected: (data: Key[]) => void
  onSettingsChange: (columns: HeadCell[]) => void
  onSelectAll?: () => void
  order?: TOrder
  orderBy?: string
  withSettings?: boolean
}

export const ExtendedTableHeader: React.FC<TExtendedTableHeader> = ({
  headerColumns,
  rows,
  selected,
  order,
  orderBy,
  withSettings,
  setSelected,
  onSelectAll,
  onSettingsChange,
  createSortHandler,
}) => {
  const [allChecked, setAllChecked] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)

  const handleSelectPageClick = useCallback(
    event => {
      event.stopPropagation()
      if (event.target.checked) {
        const newSelected = selected.concat(rows.map(row => row.id))
        setSelected(uniq(newSelected))
        return
      }
      setSelected(without(selected, ...rows.map(row => row.id)))
    },
    [rows, selected, setSelected],
  )

  useEffect(() => {
    if (!rows.length) {
      return
    }

    const isFullySelected = !difference(
      rows.map(row => row.id),
      selected,
    ).length

    switch (true) {
      case !isFullySelected && allChecked:
        setAllChecked(false)
        break
      case isFullySelected && !allChecked:
        setAllChecked(true)
        break
    }
  }, [allChecked, rows, selected])

  return (
    <TableHead className='extended-table__head'>
      <TableRow className='extended-table__row'>
        <TableCell padding='checkbox' className='extended-table__cell-checkbox'>
          <Button
            className='select-menu'
            size='small'
            endIcon={<ArrowDropDownIcon fontSize='small' />}
            onClick={event => {
              setMenuAnchor(event.currentTarget)
            }}
          >
            <Checkbox
              color={'primary'}
              checked={allChecked}
              onClick={handleSelectPageClick}
            />
          </Button>
          <Menu
            className='table-menu'
            open={!!menuAnchor}
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(null)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem
              className='menu-item'
              onClick={() => {
                if (onSelectAll) {
                  onSelectAll()
                }
                setMenuAnchor(null)
              }}
            >
              Выбрать всё
            </MenuItem>
            <MenuItem
              className='menu-item'
              onClick={() => {
                setSelected([])
                setMenuAnchor(null)
              }}
            >
              Очистить выделение
            </MenuItem>
          </Menu>
        </TableCell>
        {headerColumns.map(({ id, isHidden, label, sticky }) => {
          if (withSettings && isHidden) {
            return null
          }

          return (
            <TableCell
              className={classNames(
                'extended-table__cell extended-table__cell-head',
                { 'cell-sticky': !!sticky },
              )}
              key={id}
              sortDirection={orderBy === id ? order : false}
              title={label}
            >
              {label.length > 0 && (
                <TableSortLabel
                  className='extended-table__sort-label'
                  active={orderBy === id}
                  direction={orderBy === id ? order : 'asc'}
                  onClick={createSortHandler(id)}
                  IconComponent={ArrowDropDownIcon}
                >
                  <span className='extended-table__label'>{label}</span>
                </TableSortLabel>
              )}
            </TableCell>
          )
        })}
        <TableCell />
        {withSettings && (
          <TableCell padding='checkbox'>
            <HeaderSettings
              columns={headerColumns}
              onSettingsChange={onSettingsChange}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
