import React from 'react'
import Button from '@material-ui/core/Button'
import { NavLink } from 'react-router-dom'
import { TendersTabs } from 'src/constants/routes'

export const Headline: React.FC = () => (
  <div className='tender-headline'>
    <ul className='nav-list'>
      <li className='nav-list-item'>
        <NavLink className='nav-list-link' to={TendersTabs.new}>
          <Button className='nav-list-button' color='primary'>
            Новые
          </Button>
        </NavLink>
      </li>
      <li className='nav-list-item'>
        <NavLink className='nav-list-link' to={TendersTabs.sent}>
          <Button className='nav-list-button' color='primary'>
            Отправленные
          </Button>
        </NavLink>
      </li>
      <li className='nav-list-item'>
        <NavLink className='nav-list-link' to={TendersTabs.incoming}>
          <Button className='nav-list-button' color='primary'>
            Входящие
          </Button>
        </NavLink>
      </li>
    </ul>
  </div>
)
