import React, { Key } from 'react'
import { ReactComponent as DeleteIcon } from 'src/assets/svg/delete.svg'

import './combo-list.scss'

export type ComboListItemType = {
  title: string
  value: string
  key: Key
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K: string]: any
}

type ComboListItemProps = {
  value: ComboListItemType
  disabled?: boolean
  onDelete?(index?: Key): void
  onClick?(): void
}

export const ComboListItem: React.FC<ComboListItemProps> = ({
  value: { value, title, key },
  disabled,
  onDelete,
  onClick,
}) => (
  <div className='item' title={value} onClick={onClick}>
    <div className='item-title'>{title}</div>
    {onDelete && !disabled && (
      <DeleteIcon className='delete-icon' onClick={() => onDelete(key)} />
    )}
  </div>
)
