import React from 'react'
import classnames from 'classnames'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalProps as MuiModalProps,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import './modal.scss'

type ModalProps = {
  className?: string
  open: boolean
  title?: string
  onClose?: MuiModalProps['onClose']
  footer?: React.ElementType
  onCloseIconButton?: () => void
}

export const Modal: React.FC<ModalProps> = ({
  open,
  title = '',
  onClose,
  children,
  footer: Footer,
  className,
  onCloseIconButton,
}) => (
  <Dialog
    className={classnames('modal', className)}
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
    disableEnforceFocus={true}
  >
    <DialogTitle disableTypography>
      {title}
      {onCloseIconButton ? (
        <IconButton aria-label='close' onClick={onCloseIconButton}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>{Footer && <Footer />}</DialogActions>
  </Dialog>
)
