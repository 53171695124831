import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import { Button } from 'src/components/ui/button/button'
import { Drawer } from 'src/components/ui/drawer/drawer'
import { ExtendedTable } from 'src/components//ui/extended-table'
import { Pagination, Sorting } from 'src/types/api'
import { getAllProvidersIds, getProviders } from 'src/store/providers/actions'
import { providersStateSelector } from 'src/store/providers/selectors'
import { Key } from 'src/types/common'
import {
  resetProviders,
  setParametersProviders,
  setSelectedProviders,
} from 'src/store/providers/providers-slice'
import { PROVIDER_DEFAULT_PAGE_SIZE } from 'src/constants/providers'

import { Loader } from '../ui/loader/loader'
import { AppDispatch } from '../../store'

import { PROVIDERS_COLUMNS } from './constants'
import { fromDataToTableMapper } from './utils'
import { SearchMini } from './search-mini'
import { CatalogConnected } from './catalog-connected'

import './providers-dictionary.scss'

type ProvidersDictionaryProps = {
  open: boolean
  selectedProviders?: Key[]
  onClose?(): void
  onAdd?(selected: Key[]): void
}

export const ProvidersDictionary: React.FC<ProvidersDictionaryProps> = ({
  open,
  selectedProviders = [],
  onClose,
  onAdd,
}) => {
  const dispatch = useDispatch<AppDispatch>()

  const {
    isFetching,
    data: providers,
    totalElements,
    selected,
    filter,
  } = useSelector(providersStateSelector)

  const handleSetChosen = useCallback(
    (selected: Key[]) => {
      dispatch(setSelectedProviders(selected))
    },
    [dispatch],
  )

  const onParametersChangeHandler = useCallback(
    (parameters: Pagination & Sorting) => {
      dispatch(setParametersProviders(parameters))
      dispatch(
        getProviders({
          ...parameters,
          filter,
        }),
      ).then(() => {
        dispatch(setSelectedProviders(selectedProviders))
      })
    },
    [dispatch, filter, selectedProviders],
  )

  const onSelectAllHandler = useCallback(() => {
    dispatch(
      getAllProvidersIds({
        ...filter,
      }),
    )
  }, [dispatch, filter])
  const handleOnAdd = useCallback(() => {
    if (onAdd) {
      onAdd(selected)
    }

    if (onClose) {
      onClose()
    }
  }, [onAdd, onClose, selected])

  useEffect(() => {
    if (!open) {
      dispatch(resetProviders())
    }
  }, [dispatch, open])

  return (
    <Drawer title='Справочник контрагентов' open={open} onClose={onClose}>
      <div className='dictionary-header'>
        <CatalogConnected />
        <SearchMini />
      </div>
      <div className='dictionary-content'>
        <Loader open={isFetching} />
        {open && (
          <ExtendedTable
            headCells={PROVIDERS_COLUMNS}
            rows={fromDataToTableMapper(providers)}
            rowsPerPageOptions={[10, 20, 50]}
            beginRowsPerPage={PROVIDER_DEFAULT_PAGE_SIZE}
            handleSetChosen={handleSetChosen}
            initTypeSort='desc'
            totalElements={totalElements}
            localStorageKey='providersDictionary'
            onParametersChange={onParametersChangeHandler}
            onSelectAll={onSelectAllHandler}
            selected={selected}
            showSelectedCounter={false}
            withSettings
          />
        )}
      </div>
      <div className='dictionary-footer'>
        <div className='chosen-counter'>
          Выбрано: <span className='count'>{selected.length}</span>
        </div>
        <Button
          title='Выбрать'
          color='primary'
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleOnAdd}
        />
      </div>
    </Drawer>
  )
}
