import isNil from 'lodash/isNil'
import { Category, NestedCategory } from 'src/types/api'

export const createNestedCategories = (
  listItems: Category[],
): NestedCategory[] => {
  const resultList: NestedCategory[] = []
  const hashListItems: Record<string, NestedCategory> = listItems.reduce(
    (acc, el) => {
      const newElement =
        el.type === 'folder'
          ? {
              ...el,
              children: [],
            }
          : el

      return {
        ...acc,
        [el.id]: newElement,
      }
    },
    {},
  )

  listItems.forEach(el => {
    if (isNil(el.parentId)) {
      resultList.push(hashListItems[el.id])
      return
    }

    if (hashListItems[el.parentId].type === 'folder') {
      hashListItems[el.parentId].children?.push(hashListItems[el.id])
    }
  })

  return resultList
}
