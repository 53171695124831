import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router'
import cn from 'classnames'

import { Grid } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import { Button, Accordion } from '@ifellow/ui-library'

import { AppDispatch } from 'src/store'
import {
  getProvidersByIds,
  getProvidersByIdsLots,
  getTender,
  sendTender,
  sendTenderTz,
  saveTender,
  saveTenderTz,
  uploadFiles,
  uploadFilesLots,
} from 'src/store/tender/actions'
import {
  tenderDataSelector,
  tenderFetchStatusSelector,
  tenderFilesUploadingStatusSelector,
} from 'src/store/tender/selectors'
import {
  resetTender,
  setTenderProvidersByLots,
  setTenderFilesByLots,
  setFieldByName,
} from 'src/store/tender/tender-slice'

import { TenderData, UploadedFile } from 'src/types/api'

import { AppRoutes, TenderSources, SendTendersType } from 'src/constants/routes'
import { Modal } from 'src/components/ui/modal/modal'
import { ComboList } from 'src/components/ui/combo-list/combo-list'
import { Loader } from 'src/components/ui/loader/loader'
import { Textarea } from 'src/components/ui/textarea/textarea'
import { Input } from 'src/components/ui/input/input'
import { FileUpload } from 'src/components/ui/file-upload/file-upload'

import {
  providersToComboListFormatter,
  comboListToProvidersFormatter,
  specializationsToComboListFormatter,
} from 'src/utils/combo-list'

import { TendersTabs } from '../../constants/routes'
import { fetchTenders } from '../../store/tenders/actions'
import { ProvidersDictionary } from '../providers-dictionary/providers-dictionary'
import { DatePicker } from '../ui/date-picker/date-picker'
import { RadioInput } from '../ui/radio-input/radio-input'
import { Switch } from '../ui/switch/switch'

import { validation, validateProvidersByLot, getModalTitle } from './utils'

import './tender-modal.scss'

export const TenderModal: React.FC = () => {
  const { replace } = useHistory()
  const dispatch: AppDispatch = useDispatch()
  const { tab, source, id, type } =
    useParams<{
      tab: string
      source: TenderSources
      id: string
      type: SendTendersType
    }>()

  const tender = useSelector(tenderDataSelector)
  const { providers, attachments, isPublic } = tender

  const isModalFetching = useSelector(tenderFetchStatusSelector)
  const isFilesUploading = useSelector(tenderFilesUploadingStatusSelector)

  const [isProvidersDictionaryOpen, setIsProvidersDictionaryOpen] =
    useState(false)
  const [isShowErrors, setIsShowErrors] = useState(false)
  const [sendTzLotArrayIndex, setSendTzLotArrayIndex] =
    useState<number | null>(null)
  const [uploadTzLotArrayIndex, setUploadTzLotArrayIndex] =
    useState<number | null>(null)
  const errors = validation(tender, type, tender.lots)

  const onCloseHandler = useCallback(
    (isRejectCase = false) => {
      if (!tender.lotId) {
        replace(
          generatePath(AppRoutes.tenders as string, {
            tab,
          }),
        )
        return
      }

      if (tab === TendersTabs.new && isRejectCase) {
        if (type === SendTendersType.sendTz) {
          dispatch(saveTender(tender)).finally(() => {
            dispatch(
              fetchTenders({
                type: tab,
                options: {
                  size: 20,
                  page: 0,
                  sort: { sortBy: 'sendDateTime', direction: 'desc' },
                },
              }),
            )
          })
        } else if (type === SendTendersType.sendLot) {
          dispatch(saveTenderTz(tender)).finally(() => {
            dispatch(
              fetchTenders({
                type: tab,
                options: {
                  size: 20,
                  page: 0,
                  sort: { sortBy: 'sendDateTime', direction: 'desc' },
                },
              }),
            )
          })
        } else if (type === SendTendersType.sendLotFromMailer) {
          dispatch(
            fetchTenders({
              type: tab,
              options: {
                size: 20,
                page: 0,
                sort: { sortBy: 'sendDateTime', direction: 'desc' },
              },
            }),
          )
        }
      }

      if (
        type === SendTendersType.sendLotFromMailer &&
        !isRejectCase &&
        tab === TendersTabs.new
      ) {
        dispatch(
          fetchTenders({
            type: tab,
            options: {
              size: 20,
              page: 0,
              sort: { sortBy: 'sendDateTime', direction: 'desc' },
            },
          }),
        )
      }

      replace(
        generatePath(AppRoutes.tenders as string, {
          tab,
        }),
      )
    },
    [replace, tab, dispatch, tender, type],
  )

  const onConfirmHandler = useCallback(() => {
    if (Object.values(errors).some(error => !!error)) {
      return setIsShowErrors(true)
    }

    setIsShowErrors(false)

    const sendTenderAction =
      type === SendTendersType.sendTz ? sendTenderTz : sendTender

    if (type === SendTendersType.sendLotFromMailer) {
      const sendTenderData: TenderData = { ...tender, id }
      dispatch(sendTenderAction(sendTenderData)).finally(onCloseHandler)
    } else {
      dispatch(sendTenderAction(tender)).finally(onCloseHandler)
    }
  }, [dispatch, tender, onCloseHandler, errors, type, id])

  const onFilesUploadHandler = useCallback(
    (files: File[]) => {
      dispatch(uploadFiles(files))
    },
    [dispatch],
  )

  const onFilesUploadHandlerLots = useCallback(
    (files: File[], index) => {
      setUploadTzLotArrayIndex(index)
      dispatch(uploadFilesLots({ fl: files, lotArrayIndex: index }))
    },
    [dispatch],
  )

  const onFileDeleteHandler = useCallback(
    ({ url }: UploadedFile) => {
      dispatch(
        setFieldByName({
          field: 'attachments',
          value: attachments?.filter(
            ({ url: attachmentUrl }) => attachmentUrl !== url,
          ),
        }),
      )
    },
    [attachments, dispatch],
  )

  const onFileDeleteHandlerByLot = useCallback(
    ({ id }: UploadedFile, index) => {
      dispatch(
        setTenderFilesByLots(
          tender.lots[index].attachments.filter(
            ({ id: attachmentId }) => attachmentId !== id,
          ),
          index || 0,
        ),
      )
    },
    [dispatch, tender.lots],
  )

  const onDictionaryHandler = useCallback(
    (lotArrayIndex?: number) => {
      setSendTzLotArrayIndex(lotArrayIndex || 0)
      setIsProvidersDictionaryOpen(!isProvidersDictionaryOpen)
    },
    [isProvidersDictionaryOpen],
  )

  const onAddHandler = useCallback(
    providers => {
      type === 'sendTz'
        ? dispatch(
            getProvidersByIdsLots({
              ids: providers,
              lotArrayIndex: sendTzLotArrayIndex || 0,
            }),
          )
        : dispatch(getProvidersByIds(providers))
    },
    [dispatch, type, sendTzLotArrayIndex],
  )

  useEffect(() => {
    dispatch(getTender({ source, id, type }))

    return () => {
      dispatch(resetTender())
    }
  }, [dispatch, source, id, type])

  const disabled = type === 'outgoingMail'
  const title = getModalTitle(type)

  return (
    <>
      <Loader open={isModalFetching} />
      <Modal
        className='tender-send-modal'
        title={title}
        onClose={() => onCloseHandler(true)}
        onCloseIconButton={() => onCloseHandler(true)}
        open={!isModalFetching}
        footer={() => (
          <>
            {type !== 'outgoingMail' && (
              <>
                <Switch
                  className='switch'
                  value={!!tender.sendToFsk}
                  onChange={(_, value) => {
                    dispatch(
                      setFieldByName({
                        field: 'sendToFsk',
                        value,
                      }),
                    )
                  }}
                  label='Разместить на сайте'
                />
                <Button
                  type='secondary'
                  title='Отменить'
                  onClick={() => onCloseHandler(true)}
                />
                <Button
                  type='primary'
                  title='Отправить'
                  onClick={onConfirmHandler}
                />
              </>
            )}
          </>
        )}
      >
        <form className='form'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='subject'>{tender.subject}</div>
            </Grid>
            <Grid item xs={12}>
              {type !== 'outgoingMail' && (
                <RadioInput
                  onChange={(_, value) => {
                    dispatch(
                      setFieldByName({
                        field: 'isPublic',
                        value: Boolean(value),
                      }),
                    )
                  }}
                  value={!!isPublic}
                  options={[
                    { label: 'Открытый конкурс', value: true },
                    { label: 'Закрытый конкурс', value: false },
                  ]}
                />
              )}
              {type === 'outgoingMail' && (
                <>{isPublic ? 'Открытый конкурс' : 'Закрытый конкурс'}</>
              )}
            </Grid>
            <Grid item xs={12}>
              <Input
                label='Тип тендера'
                disabled={disabled}
                value={tender.tenderType}
                onChange={value => {
                  dispatch(
                    setFieldByName({
                      field: 'tenderType',
                      value,
                    }),
                  )
                }}
                error={isShowErrors && errors.tenderType}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label='Начало подачи заявки'
                disabled={disabled}
                disablePast={true}
                value={tender.dateStart}
                onChange={date => {
                  dispatch(
                    setFieldByName({
                      field: 'dateStart',
                      value: date?.toJSON(),
                    }),
                  )
                }}
                error={isShowErrors && errors.dateStart}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                minDate={tender.dateStart}
                value={tender.dateEnd}
                label='Конец подачи заявки'
                disabled={disabled}
                onChange={date => {
                  dispatch(
                    setFieldByName({
                      field: 'dateEnd',
                      value: date?.toJSON(),
                    }),
                  )
                }}
                error={isShowErrors && errors.dateEnd}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label='Заказчик'
                disabled={disabled}
                value={tender.customer}
                onChange={value => {
                  dispatch(
                    setFieldByName({
                      field: 'customer',
                      value,
                    }),
                  )
                }}
                error={isShowErrors && errors.customer}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label='Контактное лицо'
                disabled={disabled}
                value={tender.contacts}
                onChange={value => {
                  dispatch(
                    setFieldByName({
                      field: 'contacts',
                      value,
                    }),
                  )
                }}
                error={isShowErrors && errors.contacts}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label='E-mail'
                disabled={disabled}
                value={tender.email}
                onChange={value => {
                  dispatch(
                    setFieldByName({
                      field: 'email',
                      value,
                    }),
                  )
                }}
                error={isShowErrors && errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Textarea
                rows={4}
                rowsMax={30}
                maxLength={5500}
                label='Текст сообщения'
                disabled={disabled}
                value={tender.text}
                onChange={value => {
                  dispatch(
                    setFieldByName({
                      field: 'text',
                      value,
                    }),
                  )
                }}
              />
            </Grid>
            {type !== 'sendTz' && (
              <>
                <Grid item xs={12}>
                  <ComboList
                    label='Список контрагентов'
                    disabled={disabled}
                    addIcon={PersonAddIcon}
                    value={providersToComboListFormatter(providers)}
                    onChange={value =>
                      dispatch(
                        setFieldByName({
                          field: 'providers',
                          value: comboListToProvidersFormatter(value),
                        }),
                      )
                    }
                    onOpenDictionary={onDictionaryHandler}
                    error={isShowErrors && errors.providers}
                  />
                  {isProvidersDictionaryOpen && (
                    <ProvidersDictionary
                      open={isProvidersDictionaryOpen}
                      onClose={onDictionaryHandler}
                      selectedProviders={providers?.map(
                        item => item.externalId,
                      )}
                      onAdd={onAddHandler}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ComboList
                    label='Специализация'
                    value={specializationsToComboListFormatter(
                      tender.specializations || [],
                    )}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileUpload
                    label='Документы'
                    isDisabled={disabled}
                    value={attachments.map(el => ({
                      ...el,
                      isRequired:
                        tab === TendersTabs.sent ? true : el.isRequired,
                    }))}
                    onUpload={onFilesUploadHandler}
                    onDelete={onFileDeleteHandler}
                    isLoading={isFilesUploading}
                  />
                </Grid>
              </>
            )}
            {type === 'sendTz' && (
              <Grid item xs={12}>
                {tender?.lots.map((lot, index) => {
                  const lotProvidersError = validateProvidersByLot(
                    isShowErrors,
                    errors.providersByLot,
                    lot.providers,
                  )

                  return (
                    <Accordion
                      key={lot.lotId}
                      title={lot.lotName}
                      className={cn({ 'accordion-error': lotProvidersError })}
                    >
                      <div style={{ width: '100%' }}>
                        <Grid item xs={12}>
                          <ComboList
                            label='Список контрагентов'
                            disabled={disabled}
                            addIcon={PersonAddIcon}
                            value={providersToComboListFormatter(lot.providers)}
                            onChange={value =>
                              dispatch(
                                setTenderProvidersByLots(
                                  comboListToProvidersFormatter(value),
                                  index,
                                ),
                              )
                            }
                            onOpenDictionary={() => onDictionaryHandler(index)}
                            error={lotProvidersError}
                          />
                          <ProvidersDictionary
                            open={
                              isProvidersDictionaryOpen &&
                              index === sendTzLotArrayIndex
                            }
                            onClose={onDictionaryHandler}
                            selectedProviders={lot.providers?.map(
                              item => item.externalId,
                            )}
                            onAdd={onAddHandler}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ComboList
                            label='Специализация'
                            value={specializationsToComboListFormatter(
                              lot.specializations || [],
                            )}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FileUpload
                            label='Документы'
                            isDisabled={disabled}
                            value={[
                              ...attachments.map(el => ({
                                ...el,
                                isRequired: true,
                              })),
                              ...lot.attachments.map(el => ({
                                ...el,
                                isRequired:
                                  tab === TendersTabs.sent
                                    ? true
                                    : el.isRequired,
                              })),
                            ]}
                            onUpload={onFilesUploadHandlerLots}
                            onDelete={onFileDeleteHandlerByLot}
                            isLoading={
                              isFilesUploading &&
                              index === uploadTzLotArrayIndex
                            }
                            lotIndex={index}
                          />
                        </Grid>
                      </div>
                    </Accordion>
                  )
                })}
              </Grid>
            )}
          </Grid>
        </form>
      </Modal>
    </>
  )
}
