import DateFnsUtils from '@date-io/date-fns'
import { format, parseISO } from 'date-fns'
import locale from 'date-fns/locale/ru'

const { isBeforeDay: isBerforeDayFns } = new DateFnsUtils({ locale })

type AnyDate = Date | string | number | null

export const isBeforeDay = (firstDate?: AnyDate, secondDate?: AnyDate) =>
  isBerforeDayFns(new Date(firstDate || ''), new Date(secondDate || ''))

export const formatDateForUI = (date?: string) => {
  if (!date) {
    return ''
  }

  return format(parseISO(date), 'dd.MM.yyyy')
}
