import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '@gmini/auth'

import { IconButton, Tooltip } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { getUser } from 'src/store/user/actions'
import { RootState } from 'src/store'

import { ReactComponent as LogoIcon } from 'src/assets/svg/logo.svg'
import { ReactComponent as TenderIcon } from 'src/assets/svg/tenders.svg'
import { ReactComponent as LogoutIcon } from 'src/assets/svg/logout.svg'

import { TENDERS_ROOT_PATH } from 'src/constants/routes'

import { useNavbar } from '../../common/useNavbar'
import { getCurrentEnvModulesData } from '../../common/getCurrentEnvModulesData'

import { HeaderLink } from './header-link'

const emptyNavModules = [
  [{ id: 0, name: '', url: '' }],
  [{ id: 0, name: '', url: '' }],
]

export const Header: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const { authClient } = useAuthState()
  const links =
    window.SPA_LINKS_CONFIG || process.env.REACT_APP_SPA_LINKS_CONFIG
  const navModules = links ? getCurrentEnvModulesData(links) : null
  const { Navbar } = useNavbar({
    navModules: navModules || emptyNavModules,
  })

  const logoutHandler = useCallback(() => {
    authClient.logout()
  }, [authClient])

  const openHelpSite = useCallback(() => {
    window.open('https://gmini.freshdesk.com/a/tickets/new', '_blank')
  }, [])

  const openAccount = useCallback(() => {
    window.location.replace(
      authClient.createAccountUrl({
        redirectUri: window.location.href,
      }),
    )
  }, [authClient])

  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  return (
    <header className='app-header'>
      <div className='app-header-left-side'>
        <div className='logo'>
          <LogoIcon />
          <div className='logo-text'>Рассылка</div>
          {Navbar}
        </div>
        <nav className='navigation'>
          <HeaderLink className='navigation-link' to={TENDERS_ROOT_PATH}>
            <div>
              <TenderIcon />
            </div>
            <div className='navigation-link-text'>Тендерные задания</div>
          </HeaderLink>
          {/*
                    <HeaderLink className="navigation-link" to={ AppRoutes.specs }>
                        <div>
                            <SpecializationIcon />
                        </div>
                        <div className="navigation-link-text">Специализации</div>
                    </HeaderLink>
                    */}
        </nav>
      </div>
      <div className='app-header-right-side'>
        <div className='app-header-user-info'>
          <div className='app-header-user-info-title'>{user.fullName}</div>
          <div className='app-header-user-info-desc'>{user.role}</div>
        </div>
        <div>
          <IconButton size='small' onClick={openAccount}>
            <Tooltip title={`${user.role}: ${user.fullName}`}>
              <AccountCircleIcon style={{ color: 'white', fontSize: '24px' }} />
            </Tooltip>
          </IconButton>
        </div>
        <div className='app-header-contact-support'>
          <IconButton size='small' onClick={openHelpSite}>
            <Tooltip title='Техподдержка'>
              <ContactSupportIcon
                style={{ color: 'white', fontSize: '24px' }}
              />
            </Tooltip>
          </IconButton>
        </div>
        <div className='app-header-notification'>
          <IconButton size='small'>
            <Tooltip title='Уведомления'>
              <NotificationsIcon style={{ color: 'white', fontSize: '24px' }} />
            </Tooltip>
          </IconButton>
        </div>
        <div className='app-header-logout'>
          <IconButton size='small' onClick={logoutHandler}>
            <Tooltip title='Завершить работу'>
              <LogoutIcon />
            </Tooltip>
          </IconButton>
        </div>
      </div>
    </header>
  )
}
