import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, Route } from 'react-router-dom'
import { generatePath } from 'react-router'
import { find, get } from 'lodash'

import { XGridTable } from '@ifellow/ui-library'
import { GridSortModel, GridSelectionModel } from '@material-ui/x-grid'
import SendIcon from '@material-ui/icons/Send'
import BackspaceIcon from '@material-ui/icons/Backspace'

import { TypeTenders } from 'src/types/api'

import { AppDispatch } from 'src/store'
import { getTenders } from 'src/store/tenders/selectors'
import { fetchAllTendersIds, fetchTenders } from 'src/store/tenders/actions'
import {
  resetTenders,
  setSelectedIds,
  setPrevTab,
} from 'src/store/tenders/tenders-slice'

import {
  AppRoutes,
  TendersTabs,
  SendTendersType,
  TenderSources,
} from 'src/constants/routes'

import { withPage } from 'src/components/HOC/with-page/with-page'
import { TenderModal } from 'src/components/tender-modal/tender-modal'
import { RejectMailModal } from 'src/components/reject-modal/reject-modal'
import { Loader } from 'src/components/ui/loader/loader'
import { Button } from 'src/components/ui/button/button'

import { Headline } from './headline'

import { createGridTableColumns, createInitSortModel } from './utils'

import './tenders.scss'

const Tenders: React.FC = () => {
  const { tab } = useParams<{ tab: TypeTenders }>()
  const dispatch: AppDispatch = useDispatch()
  const { replace } = useHistory()
  const { isFetching, data, totalElements, selectedIds, prevTab } =
    useSelector(getTenders)

  const [isRejectModalOpen, setRejectModalOpen] = useState<boolean>(false)

  useEffect(
    () => () => {
      dispatch(resetTenders())
    },
    [dispatch, tab],
  )

  const parametersChangeHandler = useCallback(
    params => {
      dispatch(
        fetchTenders({
          type: tab,
          options: {
            ...params,
            sort: {
              sortBy: params?.sort?.field,
              direction: params?.sort?.sort,
            },
          },
        }),
      ).then(() => {
        if (prevTab !== tab) {
          dispatch(setSelectedIds([]))
          dispatch(setPrevTab(tab))
          return
        }

        dispatch(setSelectedIds(selectedIds))
      })
    },
    [dispatch, tab, selectedIds, prevTab],
  )

  const onSelectAllHandler = useCallback(() => {
    if (tab !== TendersTabs.new) {
      dispatch(fetchAllTendersIds(tab))
    }
  }, [tab, dispatch])

  const handleSetChosen = (selectionModel: GridSelectionModel) => {
    dispatch(setSelectedIds(selectionModel))
  }

  const handleToggleRejectModal = () => {
    setRejectModalOpen(!isRejectModalOpen)
  }

  const handleSendMail = () => {
    const chosenLotItem = find(data, { id: selectedIds[0] })
    const id = get(chosenLotItem, 'id')
    replace(
      generatePath(AppRoutes.tendersModal as string, {
        tab,
        source: TenderSources.grandTender,
        type: SendTendersType.sendLotFromMailer,
        id,
      }),
    )
  }

  const gridTableColumns = createGridTableColumns(tab)

  const initSortModel: GridSortModel = createInitSortModel(tab)

  return (
    <>
      <Loader open={isFetching} />
      <div className='tenders'>
        <Headline />
        <div className='tender-buttons'>
          {tab === 'new' && (
            <div className='tender-item-button'>
              <Button
                variant='contained'
                color='primary'
                title='Отправить'
                disabled={!(selectedIds.length === 1)}
                startIcon={<SendIcon />}
                onClick={handleSendMail}
              />
            </div>
          )}
          {(tab === 'new' || tab === 'sent') && (
            <div className='tender-item-button'>
              <Button
                variant='contained'
                color='secondary'
                type='secondary'
                title='Отменить'
                disabled={!(selectedIds.length > 0)}
                startIcon={<BackspaceIcon />}
                onClick={handleToggleRejectModal}
              />
            </div>
          )}
        </div>
        <div className='tenders-table-container'>
          <XGridTable
            key={tab}
            checkboxSelection={tab !== TendersTabs.incoming}
            columns={gridTableColumns}
            rows={data}
            mode='server'
            pageSize={20}
            pageStart={0}
            rowsPerPageOptions={[10, 20, 50]}
            selectionModel={selectedIds}
            onSelectionModelChange={handleSetChosen}
            onParametersChange={parametersChangeHandler}
            onSelectAll={onSelectAllHandler}
            rowCount={totalElements}
            initSortModel={initSortModel}
            localStorageCache={`tenders-${tab}`}
            withSettings={true}
          />
        </div>
        <RejectMailModal
          tab={tab}
          isModalOpen={isRejectModalOpen}
          selectedIds={selectedIds}
          onClose={handleToggleRejectModal}
        />

        <Route exact path={AppRoutes.tendersModal} component={TenderModal} />
      </div>
    </>
  )
}

export default withPage(Tenders)
