import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { FormLabel } from 'src/components/ui/form-label/form-label'
import { Loader } from 'src/components/ui/loader/loader'
import { UploadedFile } from 'src/types/api'
import { toastr } from 'react-redux-toastr'

import { FileUploadItem, FileUploadItemType } from './file-upload-item'

import './file-upload.scss'

type FileUploadProps = {
  label?: string
  value?: FileUploadItemType[]
  isLoading?: boolean
  isDisabled?: boolean
  lotIndex?: number
  onUpload?(files: File[], lotIndex?: number): void
  onDelete?(file: UploadedFile, lotIndex?: number): void
}

export const FileUpload: React.FC<FileUploadProps> = ({
  value,
  label = '',
  isDisabled,
  lotIndex,
  isLoading = false,
  onUpload,
  onDelete,
}) => {
  const onDrop = useCallback(
    acceptedFiles => {
      if (onUpload && !isDisabled) {
        onUpload(acceptedFiles, lotIndex)
      } else {
        toastr.warning(
          'Уведомление',
          'Вы не можете загружать файлы в режиме просмотра',
        )
      }
    },
    [onUpload, isDisabled, lotIndex],
  )

  const deleteFile = useCallback(
    file => {
      if (onDelete) {
        onDelete(file, lotIndex)
      }
    },
    [onDelete, lotIndex],
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <>
      <FormLabel label={label} />
      <div className='dropdown-zone' {...getRootProps()}>
        <Loader onClick={event => event.stopPropagation()} open={isLoading} />
        <input {...getInputProps()} />
        <div className='file-list'>
          {value?.map(({ name, url, id, ...rest }) => (
            <FileUploadItem
              id={id}
              name={name}
              url={url}
              {...rest}
              key={`${name}-${url}`}
              onDelete={deleteFile}
            />
          ))}
        </div>
        {!isDisabled && (
          <div className='attach'>
            <AttachFileIcon />
            Кликните или перетащите файл
          </div>
        )}
      </div>
    </>
  )
}
