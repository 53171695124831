import React, { useCallback } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import { withMask } from 'src/components/HOC/with-mask'
import classnames from 'classnames'

import './input.scss'

type InputProps = Omit<
  TextFieldProps,
  'multiline' | 'variant' | 'onChange' | 'error'
> & {
  error?: string | boolean
  maxLength?: number
  onChange(value: string): void
  startIcon?: React.ReactNode
  className?: string
}

export const Input: React.FC<InputProps> = ({
  value,
  error,
  fullWidth = true,
  label,
  maxLength = 200,
  onChange,
  startIcon,
  className,
  ...rest
}) => {
  const onChangeHandler = useCallback(
    event => {
      onChange(event.target.value)
    },
    [onChange],
  )

  return (
    <TextField
      className={classnames('mailer-input', className)}
      fullWidth={fullWidth}
      label={label}
      value={value || ''}
      {...rest}
      error={!!error}
      helperText={typeof error === 'string' ? error : ''}
      onChange={onChangeHandler}
      variant='outlined'
      inputProps={{
        maxLength,
      }}
      InputProps={{
        startAdornment: startIcon,
      }}
    />
  )
}

export const MaskedInput = withMask<InputProps>(Input)
