import React, { Suspense } from 'react'
// FIXME: Replace this import on relative import (same package)

import { AuthProvider } from '@gmini/auth'
import { Provider } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'

import ruLocale from 'date-fns/locale/ru'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StylesProvider, createGenerateClassName } from '@material-ui/core'
import { EmptyTokenException } from 'src/services/apiService/exceptions/emptyToken.exception'

import { apiClient } from 'src/services/ApiService'

import { authClient } from 'src/keycloak'

import { App } from '../../app'

import { store } from '../../store'

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
})

const tokenLogger = (tokens: {
  idToken?: string | undefined
  refreshToken?: string | undefined
  token?: string | undefined
}): void => {
  if (!tokens.token) {
    throw new EmptyTokenException()
  }
  apiClient.setToken(tokens.token)
}

// TODO move auth component to common library

export const Auth = (): JSX.Element => (
  <AuthProvider authClient={authClient} onTokens={tokenLogger}>
    {({ initialized, authClient: client }) => {
      if (!initialized && !client.authenticated) {
        return <></>
      }

      if (initialized && client.authenticated) {
        client.updateToken(5)

        apiClient.setToken(client.token)
      }

      return (
        <div style={{ height: '600px' }}>
          <Provider store={store}>
            <StylesProvider injectFirst generateClassName={generateClassName}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <Suspense fallback={<div>Loading...</div>}>
                  <App />
                </Suspense>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </Provider>
        </div>
      )
    }}
  </AuthProvider>
)
