import { generatePath } from 'react-router'

export enum TenderSources {
  grandTender = 'grandtender',
  tender = 'tender',
}

export enum SendTendersType {
  sendLot = 'sendLot',
  sendLotFromMailer = 'sendLotFromMailer',
  sendTz = 'sendTz',
  outgoingMail = 'outgoingMail',
}

export enum TendersTabs {
  new = 'new',
  sent = 'sent',
  incoming = 'incoming',
}

export enum AppRoutes {
  root = '/',
  tenders = '/tenders/:tab',
  tenderDetail = '/tenders/:tab/detail/:id',
  tendersModal = '/tenders/:tab/:source/:type/:id',
  specs = '/specs',
}

export const TENDERS_ROOT_PATH = '/tenders'

export const TENDERS_START_PATH = generatePath(AppRoutes.tenders, {
  tab: TendersTabs.new,
})

export const REDIRECT_TO_TENDERS_PATHS = [AppRoutes.root, TENDERS_ROOT_PATH]
