import { createSlice } from '@reduxjs/toolkit'

import { OutgoingMail } from '../../types/api'

import { getIncomingMail } from './actions'

export type TenderDetailInitialState = {
  isLoading: boolean
  data: OutgoingMail
}

const initialState: TenderDetailInitialState = {
  isLoading: false,
  data: {} as OutgoingMail,
}

const tenderDetail = createSlice({
  name: 'tenderDetail',
  initialState,
  reducers: {
    resetTenderDetail: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getIncomingMail.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getIncomingMail.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.data = payload
    })
    builder.addCase(getIncomingMail.rejected, state => {
      state.isLoading = false
    })
  },
})

export const { resetTenderDetail } = tenderDetail.actions

export default tenderDetail.reducer
