import React from 'react'
import classnames from 'classnames'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import { Key } from 'src/types/common'

import type { TRow, HeadCell } from './extended-table'

export type TExtendedTableBody = {
  selected: Key[]
  rows: TRow[]
  setSelected: (data: Key[]) => void
  headerColumns: HeadCell[]
  withSettings?: boolean
}

export const ExtendedTableBody: React.FC<TExtendedTableBody> = ({
  rows,
  selected,
  setSelected,
  headerColumns,
  withSettings,
}) => {
  /** Проверяет выбранные строки в таблице */
  const isSelected = (name: Key) => selected.indexOf(name) !== -1

  const handleCheckboxBodyColumn = (id: Key) => () => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: Key[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else {
      newSelected = newSelected.concat(selected.filter(one => one !== id))
    }
    setSelected(newSelected)
  }

  const displayedColumns = headerColumns.reduce((acc: HeadCell[], el) => {
    if (withSettings && el.isHidden) {
      return acc
    }

    return [...acc, el]
  }, [])

  return (
    <TableBody>
      {rows.map(row => {
        const isItemSelected = isSelected(row.id)

        return (
          <TableRow className='extended-table__row' key={row.id} hover>
            <TableCell
              padding='checkbox'
              className='extended-table__cell-checkbox--body'
            >
              <Checkbox
                color={'primary'}
                onChange={handleCheckboxBodyColumn(row.id)}
                checked={isItemSelected}
              />
            </TableCell>
            {displayedColumns.map(({ id, sticky }) => {
              const title =
                typeof row[id] === 'object' && !Array.isArray(row[id])
                  ? row[id]?.props?.title
                  : row[id]

              return (
                <TableCell
                  key={`${row.id}${id}`}
                  className={classnames(
                    // eslint-disable-next-line no-useless-concat
                    'extended-table__cell ' + 'extended-table__cell-body',
                    { 'cell-sticky cell-sticky--body': !!sticky },
                  )}
                  title={title}
                >
                  {row[id]}
                </TableCell>
              )
            })}
            <TableCell />
            {withSettings && <TableCell />}
          </TableRow>
        )
      })}
    </TableBody>
  )
}
