import React from 'react'
import { format, parseISO } from 'date-fns'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { ReactComponent as MessageIcon } from 'src/assets/svg/message.svg'

import './detail-mail.scss'

export type DetailMailProps = {
  customer: string
  tenderType: string
  dateStart: string
  dateEnd: string
  contacts: string
  email: string
  text: string
}

export const DetailMail: React.FC<DetailMailProps> = ({
  customer,
  tenderType,
  dateStart,
  dateEnd,
  contacts,
  email,
  text,
}) => {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div className='detail-mail'>
        <div className='detail-mail__title'>Детали рассылки</div>
        <div className='detail-mail-list'>
          <div className='detail-mail-list__item detail-mail-item'>
            <div className='detail-mail-item__title'>Заказчик</div>
            <div className='detail-mail-item__desc'>{customer}</div>
          </div>
          <div className='detail-mail-list__item detail-mail-item'>
            <div className='detail-mail-item__title'>Тип тендера</div>
            <div className='detail-mail-item__desc'>{tenderType}</div>
          </div>
          <div className='detail-mail-list__item detail-mail-item'>
            <div className='detail-mail-item__title'>
              Дата начала приема предложений
            </div>
            <div className='detail-mail-item__desc'>
              {dateStart && format(parseISO(dateStart), 'dd.MM.yyyy')}
            </div>
          </div>
          <div className='detail-mail-list__item detail-mail-item'>
            <div className='detail-mail-item__title'>
              Дата окончания приема предложений
            </div>
            <div className='detail-mail-item__desc'>
              {dateEnd && format(parseISO(dateEnd), 'dd.MM.yyyy')}
            </div>
          </div>
          <div className='detail-mail-list__item detail-mail-item'>
            <div className='detail-mail-item__title'>Контактное лицо </div>
            <div className='detail-mail-item__desc'>{contacts}</div>
          </div>
          <div className='detail-mail-list__item detail-mail-item'>
            <div className='detail-mail-item__title'>Email</div>
            <div className='detail-mail-item__desc'>{email}</div>
          </div>
          <div className='detail-mail-list__item detail-mail-item'>
            <Button
              className='message-button'
              startIcon={<MessageIcon />}
              onClick={handleOpen}
            >
              Текст сообщения
            </Button>
          </div>
        </div>
      </div>
      <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle>Текст сообщения</DialogTitle>
        <DialogContent>
          {!!text?.length && <div>{text}</div>}
          {!text?.length && <div>В сообщении ничего не содержится</div>}
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={handleClose}>
            ок
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
