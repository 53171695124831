import React from 'react'
import classnames from 'classnames'
import { ReactComponent as FileIcon } from 'src/assets/svg/file.svg'
import { ReactComponent as DeleteIcon } from 'src/assets/svg/delete.svg'

import './file-upload.scss'

export enum FileUploadItemStatus {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export type FileUploadItemType = {
  id: number
  name: string
  url: string
  isRequired?: boolean
  fileStatus?: FileUploadItemStatus
}

type FileUploadItemProps = FileUploadItemType & {
  onDelete?: (file: FileUploadItemType) => void
}

const getStatusClassName = (fileStatus?: FileUploadItemStatus) => {
  if (!fileStatus) {
    return undefined
  }
  return `item-${fileStatus}`
}

export const FileUploadItem: React.FC<FileUploadItemProps> = ({
  id,
  name,
  url,
  onDelete,
  isRequired,
  fileStatus,
}) => (
  <div className={classnames('item', getStatusClassName(fileStatus))}>
    <a
      className='link'
      href={url}
      download='file'
      title={name}
      onClick={event => event.stopPropagation()}
    >
      <FileIcon className='file-icon' />
      <div className='link-text'>{name}</div>
    </a>
    {!isRequired && (
      <DeleteIcon
        className='delete'
        onClick={event => {
          event.stopPropagation()
          if (onDelete) {
            onDelete({ name, url, id })
          }
        }}
      />
    )}
  </div>
)
