import { BaseTextFieldProps } from '@material-ui/core'
import React, { useCallback, useRef } from 'react'

type Props = Omit<BaseTextFieldProps, 'error'> & {
  onChange(value: string): void
  value: unknown
  mask?: RegExp
  error?: string | boolean
}

// TODO: WIP

type WithMask = <T = Record<string, unknown>>(
  Wrapped: React.ComponentType<Props>,
) => React.FC<Props & T>

export const withMask: WithMask =
  WrappedComponent =>
  // eslint-disable-next-line react/display-name
  ({ onChange, value, mask, ...rest }) => {
    const currentValue = useRef(value || '')

    const onChangeHandler = useCallback(
      (newValue: string) => {
        currentValue.current = newValue
        onChange(newValue)
      },
      [onChange],
    )

    return (
      <WrappedComponent value={value} onChange={onChangeHandler} {...rest} />
    )
  }
