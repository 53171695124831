import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../'

export const getTenders = createSelector(
  (state: RootState) => state.tenders,
  tenders => tenders,
)

export const getTendersData = createSelector(
  getTenders,
  tenders => tenders.data,
)
