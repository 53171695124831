import React from 'react'

import './form-label.scss'

type FormLabelProps = {
  label: string
}

export const FormLabel: React.FC<FormLabelProps> = ({ label }) => (
  <label className='form-list-label'>{label}</label>
)
