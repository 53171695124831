import { AxiosResponse } from 'axios'

import { ApiService } from './apiService/apiService'

const API_URL = '/api/tender-mailer/v1'

export type ApiResponse<T> = [Error, AxiosResponse<T>]

export const apiClient = new ApiService({
  baseUrl: API_URL,
  validation: true,
})
