import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'

import { IconButton, Button } from '@material-ui/core'

import { FormLabel } from 'src/components/ui/form-label/form-label'

import { ComboListItem, ComboListItemType } from './combo-list-item'

import './combo-list.scss'

type ComboListProps = {
  value: ComboListItemType[]
  error?: boolean | string
  label?: string
  hideItemsAfter?: number
  disabled?: boolean
  addIcon?: React.ElementType
  onChange?(value: ComboListItemType[]): void
  onOpenDictionary?(): void
  className?: string
}

export const ComboList: React.FC<ComboListProps> = ({
  value,
  error,
  label,
  disabled,
  hideItemsAfter = 6,
  onChange,
  onOpenDictionary,
  addIcon: AddIcon,
  className,
}) => {
  const [dividedValue, setDividedValue] = useState<{
    shown: ComboListItemType[]
    hidden: ComboListItemType[]
  }>({ shown: [], hidden: [] })
  const [isShowHiddenValue, setIsShowHiddenValue] = useState(false)
  const valueLength = useRef(value.length)

  const onDeleteHandler = useCallback(
    deletedId => {
      if (onChange) {
        onChange(value.filter(({ key }) => key !== deletedId))
      }
    },
    [onChange, value],
  )

  useEffect(() => {
    setDividedValue({
      shown: value.slice(0, hideItemsAfter),
      hidden: value.slice(hideItemsAfter, value.length),
    })
  }, [value, hideItemsAfter])

  useEffect(() => {
    if (valueLength.current < value.length) {
      setIsShowHiddenValue(false)
    }

    valueLength.current = value.length
  }, [value])

  return (
    <div className={classnames('combo-list', className)}>
      <div className='label'>
        {label && <FormLabel label={label} />}
        {onOpenDictionary && !disabled && (
          <IconButton onClick={onOpenDictionary}>
            {AddIcon && <AddIcon className='add-icon' />}
          </IconButton>
        )}
      </div>
      <div className='container'>
        {isShowHiddenValue && !!dividedValue.hidden.length && (
          <Button
            className='combo-list-hide-button'
            variant='contained'
            color='primary'
            onClick={() => setIsShowHiddenValue(false)}
          >
            Скрыть
          </Button>
        )}
        {dividedValue.shown.map(item => (
          <ComboListItem
            key={item.key}
            value={item}
            onDelete={onDeleteHandler}
            disabled={disabled}
          />
        ))}
        {!isShowHiddenValue && !!dividedValue.hidden.length && (
          <div
            className='more-button item'
            onClick={() => setIsShowHiddenValue(true)}
          >
            Ещё ({dividedValue.hidden.length})...
          </div>
        )}
        {isShowHiddenValue &&
          !!dividedValue.hidden.length &&
          dividedValue.hidden.map(item => (
            <ComboListItem
              key={item.key}
              value={item}
              onDelete={onDeleteHandler}
              disabled={disabled}
            />
          ))}
      </div>

      <div className='error'>{error}</div>
    </div>
  )
}
