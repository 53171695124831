import React from 'react'
import { FormControlLabel, FormControlLabelProps } from '@material-ui/core'
import BaseSwitch from '@material-ui/core/Switch'

type Props = Omit<FormControlLabelProps, 'control'> & {
  value: boolean
  label: string
}

export const Switch: React.FC<Props> = ({
  value,
  label,
  className,
  onChange,
  ...rest
}) => (
  <FormControlLabel
    className={className}
    labelPlacement='start'
    {...rest}
    label={label}
    control={<BaseSwitch onChange={onChange} checked={value} color='primary' />}
  />
)
