import React, { useCallback } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'

import './textarea.scss'

type TextareaProps = Omit<
  TextFieldProps,
  'multiline' | 'variant' | 'onChange' | 'error'
> & {
  error?: string | boolean
  maxLength?: number
  onChange(value: string): void
}

export const Textarea: React.FC<TextareaProps> = ({
  label = 'Введите текст',
  rows = 4,
  fullWidth = true,
  onChange,
  value,
  error,
  maxLength = 1500,
  ...rest
}) => {
  const onChangeHandler = useCallback(
    event => {
      onChange(event.target.value)
    },
    [onChange],
  )

  return (
    <TextField
      className='mailer-textarea'
      label={label}
      rows={rows}
      fullWidth={fullWidth}
      value={value || ''}
      {...rest}
      error={!!error}
      helperText={typeof error === 'string' ? error : ''}
      onChange={onChangeHandler}
      variant='filled'
      inputProps={{
        maxLength,
      }}
      multiline
    />
  )
}
