import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../index'

export const getMaintenanceSelector = (
  state: RootState,
): RootState['maintenance'] => state.maintenance

export const getMaintenance = createSelector(
  getMaintenanceSelector,
  state => state.enabled,
)
