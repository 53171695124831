import { get } from 'lodash'

import { HeadCell } from './extended-table'
import { TableSettings } from './types'

export const getLocalTableSettings = (localStorageKey?: string) => {
  if (!localStorageKey) {
    return null
  }

  try {
    const localSettingsJSON = localStorage.getItem(localStorageKey || '')
    return localSettingsJSON && JSON.parse(localSettingsJSON)
  } catch (error) {
    return null
  }
}

export const setLocalTableSettings = (
  localStorageKey: string,
  settings: Partial<TableSettings>,
) => {
  const localSettings = getLocalTableSettings(localStorageKey)

  localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      ...localSettings,
      ...settings,
    }),
  )
}

export const getSetting = <T>(
  defaultValue: T,
  settingKey: string,
  withSettings?: boolean,
  localStorageKey?: string,
): T => {
  if (withSettings) {
    return get<T>(
      getLocalTableSettings(localStorageKey),
      settingKey,
      defaultValue,
    )
  }

  return defaultValue
}

export const getColumns = (
  headCells: HeadCell[],
  withSettings?: boolean,
  localStorageKey?: string,
): HeadCell[] =>
  getSetting<HeadCell[]>(headCells, 'columns', withSettings, localStorageKey)

export const getRowsPerPage = (
  beginRowsPerPage: number,
  withSettings?: boolean,
  localStorageKey?: string,
): number =>
  getSetting(beginRowsPerPage, 'rowsPerPage', withSettings, localStorageKey)
