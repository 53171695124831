import React, { useState } from 'react'
import { NestedCategory } from 'src/types/api'
import { Button } from 'src/components/ui/button/button'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { Loader } from '../loader/loader'

import { CatalogMenu } from './catalog-menu'
import { CatalogMenuItem } from './catalog-menu-item'

type CatalogProps = {
  items: NestedCategory[]
  isLoading?: boolean
  handleClickNode?: (node: NestedCategory) => void
}

export const Catalog: React.FC<CatalogProps> = ({
  items,
  isLoading,
  handleClickNode,
}) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorMenuEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorMenuEl(null)

  const [openElement, setOpenElement] = useState<Record<string, boolean>>({})
  const handleToggleElement = (node: NestedCategory) => (): void => {
    setOpenElement({ ...openElement, [node.id]: !openElement[node.id] })
    handleClickNode && handleClickNode(node)
  }

  return (
    <div className='catalog'>
      {isLoading && <Loader open={isLoading} iconSize={20} />}
      <Button
        title='Каталог'
        color='primary'
        variant='contained'
        endIcon={<ArrowDropDownIcon />}
        onClick={handleOpenMenu}
      />
      <CatalogMenu
        anchorMenuEl={anchorMenuEl}
        handleCloseMenu={handleCloseMenu}
      >
        {items.map(el => (
          <CatalogMenuItem
            key={el.id}
            open={openElement}
            handleClickNode={handleToggleElement}
            node={el}
          />
        ))}
      </CatalogMenu>
    </div>
  )
}
