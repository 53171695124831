import { createAsyncThunk } from '@reduxjs/toolkit'
import { Key } from 'react'
import { apiClient } from 'src/services/ApiService'
import {
  Pagination,
  Sorting,
  Provider,
  WithOptions,
  Filters,
  Category,
} from 'src/types/api'
import { createNestedCategories } from 'src/utils/categories-utils'

export const getProviders = createAsyncThunk<
  WithOptions<Provider[]>,
  Pagination & Sorting & Filters
>('getProviders', async (params, { rejectWithValue }) => {
  const [err, result] = await apiClient.post<any, WithOptions<Provider[]>>({
    path: '/provider',
    body: params,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  const data = {
    ...result.data,
    content: result.data.content.map(item => ({
      ...item,
      externalId: item.id,
    })),
  }

  return data
})

export const getAllProvidersIds = createAsyncThunk<Key[], Filters['filter']>(
  'getAllProvidersIds',
  async (params, { rejectWithValue }) => {
    const [err, result] = await apiClient.post<any, Key[]>({
      path: '/provider/all',
      body: params,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return result.data
  },
)

export const getProviderCategories = createAsyncThunk(
  'getProviderCategories',
  async (_, { rejectWithValue }) => {
    const [err, resp] = await apiClient.get<Category[]>({
      path: '/provider/catalog',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return createNestedCategories(resp.data)
  },
)
