import React from 'react'
import { useHistory } from 'react-router-dom'
import DateFnsAdapter from '@date-io/date-fns'

import { Button, Typography } from '@material-ui/core'

import { ArrowLeftIcon } from '@ifellow/ui-library'

import './header.scss'

const dateFns = new DateFnsAdapter()

export type HeaderProps = {
  tenderTaskNumber: number
  lotName: string
  sendDateTime: string
}

export const Header: React.FC<HeaderProps> = ({
  tenderTaskNumber,
  lotName,
  sendDateTime,
}) => {
  const history = useHistory()

  return (
    <div className='header'>
      <div className='header__col-left'>
        <Button
          className='header__back-btn'
          onClick={(): void => history.goBack()}
          variant='contained'
          startIcon={<ArrowLeftIcon />}
        />
        <span className='header__title'>
          Тендерное задание №{tenderTaskNumber} / {lotName}
        </span>
      </div>
      <div className='header__col-right'>
        <div>
          <Typography color='primary'>Отправлено поставщикам</Typography>
          {sendDateTime && (
            <Typography>
              {dateFns.format(dateFns.date(sendDateTime), 'dd.MM.YYY kk:mm')}
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}
