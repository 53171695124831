import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import {
  providersParametersSelector,
  providersFilterSelector,
  providersStateSelector,
} from 'src/store/providers/selectors'
import { getProviders } from 'src/store/providers/actions'
import { useDebouncedCallback } from 'src/utils/use-debounced-callback'
import { setFiltersProviders } from 'src/store/providers/providers-slice'

import { Input } from '../ui/input/input'

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {}

export const SearchMini: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const { keyword, ...restFilters } = useSelector(providersFilterSelector)
  const providersParameters = useSelector(providersParametersSelector)
  const { isEmpty } = useSelector(providersStateSelector)
  const getProvidersDebounced = useDebouncedCallback(
    (value: string) => {
      dispatch(
        getProviders({
          ...providersParameters,
          page: 0,
          filter: {
            ...restFilters,
            keyword: value,
          },
        }),
      )
    },
    500,
    [],
  )

  const setKeyword = useCallback(
    (value: string) => {
      dispatch(
        setFiltersProviders({
          filter: {
            keyword: value,
          },
        }),
      )
    },
    [dispatch],
  )

  return (
    <div className='search-mini'>
      <Input
        placeholder='Поиск...'
        startIcon={<SearchIcon />}
        value={keyword}
        onChange={value => {
          setKeyword(value)
          getProvidersDebounced(value)
        }}
      />
      {isEmpty && <div className='search-mini-note'>Ничего не найдено</div>}
    </div>
  )
}
