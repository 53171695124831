import { createAsyncThunk } from '@reduxjs/toolkit'
import { Key } from 'react'
import { TableParams } from 'src/components/ui/extended-table/extended-table'
import { apiClient } from 'src/services/ApiService'
import { TenderData, TypeTenders, WithOptions } from 'src/types/api'

type TFetchTenders = {
  type: TypeTenders
  options: TableParams
}

export const fetchTenders = createAsyncThunk<
  WithOptions<TenderData[]>,
  TFetchTenders
>('tenders/fetchTenders', async ({ type, options }, { rejectWithValue }) => {
  const tenderActions = {
    new: 'outgoing_mail/new',
    sent: 'outgoing_mail/sent',
    incoming: 'outgoing_mail/answered',
  }
  const [err, result] = await apiClient.post<any, WithOptions<TenderData[]>>({
    path: `/${tenderActions[type]}`,
    body: options,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return result.data
})

export const fetchAllTendersIds = createAsyncThunk<
  Key[],
  TFetchTenders['type']
>('tenders/fetchAllTendersIds', async (type, { rejectWithValue }) => {
  const tenderActions = {
    new: '',
    sent: 'outgoing_mail/all',
    incoming: 'answered/all',
  }
  const [err, result] = await apiClient.get<Key[]>({
    path: `/${tenderActions[type]}`,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return result.data
})
