import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TenderData } from 'src/types/api'
import { get } from 'lodash'

import {
  getProvidersByIds,
  getTender,
  sendTender,
  saveTender,
  saveTenderTz,
  rejectMail,
  uploadFiles,
  uploadFilesLots,
  getProvidersByIdsLots,
} from './actions'

export type TenderState = {
  isModalFetching: boolean
  isFilesUploading: boolean
  data: TenderData
}

const initialState: TenderState = {
  isModalFetching: false,
  isFilesUploading: false,
  data: {
    id: '',
    tenderTaskNumber: '',
    lotName: '',
    subject: '',
    text: '',
    providers: [],
    attachments: [],
    isPublic: false,
    sendToFsk: false,
    dateEnd: '',
    dateStart: '',
    tenderType: '',
    customer: '',
    contacts: '',
    email: '',
    lots: [],
  },
}

export const tenderSlice = createSlice({
  name: 'tender',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TenderData>) => {
      state.data = action.payload
    },
    setFieldByName: <T extends keyof TenderData>(
      state: TenderState,
      action: PayloadAction<{ field: T; value: TenderData[T] }>,
    ) => {
      state.data[action.payload.field] = action.payload.value
    },
    setProvidersByLot: {
      reducer: (state, action: PayloadAction<TenderData['providers']>) => {
        const lotIndex = get(action, 'meta.lotId')
        state.data.lots[lotIndex].providers = action.payload || []
      },
      prepare: (payload, lotIndex) => ({
        payload: [...payload],
        meta: { lotId: lotIndex },
      }),
    },
    setFilesByLots: {
      reducer: (state, action: PayloadAction<TenderData['attachments']>) => {
        const lotIndex = get(action, 'meta.lotId')

        state.data.lots[lotIndex].attachments = action.payload || []
      },
      prepare: (payload, lotIndex) => ({
        payload: [...payload],
        meta: { lotId: lotIndex },
      }),
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getTender.pending, state => {
      state.isModalFetching = true
    })
    builder.addCase(getTender.fulfilled, (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      }
      state.isModalFetching = false
    })
    builder.addCase(getTender.rejected, state => {
      state.isModalFetching = false
    })

    builder.addCase(uploadFiles.pending, state => {
      state.isFilesUploading = true
    })
    builder.addCase(uploadFiles.fulfilled, (state, action) => {
      state.data.attachments = [
        ...(state.data.attachments || []),
        ...(action.payload || []),
      ]
      state.isFilesUploading = false
    })
    builder.addCase(uploadFiles.rejected, state => {
      state.isFilesUploading = false
    })

    builder.addCase(uploadFilesLots.pending, state => {
      state.isFilesUploading = true
    })
    //TODO fix ? optional
    builder.addCase(uploadFilesLots.fulfilled, (state, action) => {
      state.data.lots[action.meta.arg.lotArrayIndex].attachments = [
        ...state.data.lots[action.meta.arg.lotArrayIndex].attachments,
        ...(action.payload || []),
      ]
      state.isFilesUploading = false
    })
    builder.addCase(uploadFilesLots.rejected, state => {
      state.isFilesUploading = false
    })

    builder.addCase(sendTender.pending, state => {
      state.isModalFetching = true
    })
    builder.addCase(sendTender.fulfilled, () => initialState)
    builder.addCase(sendTender.rejected, state => {
      state.isModalFetching = false
    })

    builder.addCase(saveTender.pending, state => {
      state.isModalFetching = true
    })
    builder.addCase(saveTender.fulfilled, () => initialState)
    builder.addCase(saveTender.rejected, state => {
      state.isModalFetching = false
    })

    builder.addCase(saveTenderTz.pending, state => {
      state.isModalFetching = true
    })
    builder.addCase(saveTenderTz.fulfilled, () => initialState)
    builder.addCase(saveTenderTz.rejected, state => {
      state.isModalFetching = false
    })

    builder.addCase(rejectMail.pending, state => {
      state.isModalFetching = true
    })
    builder.addCase(rejectMail.fulfilled, () => initialState)
    builder.addCase(rejectMail.rejected, state => {
      state.isModalFetching = false
    })

    builder.addCase(getProvidersByIds.pending, state => {
      state.isModalFetching = true
    })
    builder.addCase(getProvidersByIds.fulfilled, (state, action) => {
      state.data.providers = action.payload
      state.isModalFetching = false
    })
    builder.addCase(getProvidersByIds.rejected, state => {
      state.isModalFetching = false
    })

    builder.addCase(getProvidersByIdsLots.pending, state => {
      state.isModalFetching = true
    })
    builder.addCase(getProvidersByIdsLots.fulfilled, (state, action) => {
      const lotIndex = action.meta.arg.lotArrayIndex
      state.data.lots[lotIndex].providers = [...action.payload]
      state.isModalFetching = false
    })
    builder.addCase(getProvidersByIdsLots.rejected, state => {
      state.isModalFetching = false
    })
  },
})

export const {
  setData: setTenderData,
  setFieldByName,
  setProvidersByLot: setTenderProvidersByLots,
  setFilesByLots: setTenderFilesByLots,
  reset: resetTender,
} = tenderSlice.actions

export default tenderSlice.reducer
