import { HeadCell } from '../ui/extended-table/extended-table'

export const PROVIDERS_COLUMNS: HeadCell[] = [
  { id: 'name', label: 'Название', required: true, sticky: true },
  { id: 'email', label: 'Email' },
  { id: 'sbConclusion', label: 'Заключение СБ' },
  { id: 'sbConclusionDate', label: 'Дата заключения СБ' },
  { id: 'specialisations', label: 'Специализация' },
  { id: 'inn', label: 'ИНН', isHidden: true },
  { id: 'kpp', label: 'КПП', isHidden: true },
]
