import { Provider } from 'src/types/api'
import { Key } from 'src/types/common'
import { formatDateForUI } from 'src/utils/date'

import { TRow } from '../ui/extended-table/extended-table'

export const fromDataToTableMapper = (providers: Provider[]): TRow[] =>
  providers.map(provider => ({
    ...provider,
    id: provider.externalId as Key,
    sbConclusionDate: formatDateForUI(provider.sbConclusionDate),
    specialisations: provider.specialisations?.join(', '),
  }))
