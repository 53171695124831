import React from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'

import { NestedCategory } from '../../../types/api'

export type MenuItemProps = {
  node: NestedCategory
  open: Record<string, boolean>
  handleClickNode: (item: NestedCategory) => () => void
  depthLevel?: number
}
export const CatalogMenuItem: React.FC<MenuItemProps> = ({
  node,
  open,
  handleClickNode,
  depthLevel = 1,
}) => {
  if (node.type === 'list') {
    return (
      <ListItem
        style={{
          paddingLeft: 16 * (depthLevel - 1),
        }}
        button
        key={node.id}
        onClick={handleClickNode(node)}
      >
        <ListItemIcon />
        <ListItemText>{node.name}</ListItemText>
      </ListItem>
    )
  }

  return (
    <>
      <ListItem
        style={{
          paddingLeft: 16 * depthLevel,
        }}
        button
        key={node.id}
        onClick={handleClickNode(node)}
      >
        <ListItemIcon>
          {!open[node.id] && <KeyboardArrowRight color='primary' />}
          {open[node.id] && <KeyboardArrowDown color='primary' />}
        </ListItemIcon>
        <ListItemText>{node.name}</ListItemText>
      </ListItem>
      <Collapse in={open[node.id]} timeout='auto' unmountOnExit>
        {node.children?.map(el => (
          <CatalogMenuItem
            key={el.id}
            open={open}
            handleClickNode={handleClickNode}
            node={el}
            depthLevel={depthLevel + 1}
          />
        ))}
      </Collapse>
    </>
  )
}
