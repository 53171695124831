import { createAsyncThunk } from '@reduxjs/toolkit'

import { User } from 'src/types/api'

import { apiClient } from '../../services/ApiService'

export const getUser = createAsyncThunk<{ status: string; data: User }>(
  'user/getUser',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<{ status: string; data: User }>({
      path: '/me',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)
