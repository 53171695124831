import React, { useState, Key } from 'react'
import { useDispatch } from 'react-redux'

import { ModalProps as MuiModalProps } from '@material-ui/core'

import { AppDispatch } from 'src/store'
import { fetchTenders } from 'src/store/tenders/actions'
import { rejectMail } from 'src/store/tender/actions'

import { Modal } from 'src/components/ui/modal/modal'
import { Button } from 'src/components/ui/button/button'
import { setSelectedIds } from 'src/store/tenders/tenders-slice'

import { RadioInput } from '../ui/radio-input/radio-input'
import { TendersTabs } from '../../constants/routes'

import './reject-modal.scss'

export type RejectMailModalProps = {
  tab: TendersTabs
  selectedIds: Key[]
  isModalOpen: boolean
  onClose: () => void
}

export const RejectMailModal: React.FC<RejectMailModalProps> = ({
  tab,
  selectedIds,
  isModalOpen,
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch()

  const [reason, setReason] = useState<string | null>(null)

  const onConfirmRejectMail = () => {
    if (tab === TendersTabs.new) {
      const data = {
        mailIds: selectedIds,
      }
      dispatch(rejectMail(data)).finally(() => {
        dispatch(setSelectedIds([]))
        dispatch(
          fetchTenders({
            type: tab,
            options: {
              size: 20,
              page: 0,
              sort: { sortBy: 'sendDateTime', direction: 'desc' },
            },
          }),
        )
      })
    } else {
      const data = {
        mailIds: selectedIds,
        reason: reason || '',
      }
      dispatch(rejectMail(data)).finally(() => {
        dispatch(setSelectedIds([]))
        dispatch(
          fetchTenders({
            type: tab,
            options: {
              size: 20,
              page: 0,
              sort: { sortBy: 'sendDateTime', direction: 'desc' },
            },
          }),
        )
      })
    }
    setReason(null)
    onClose()
  }

  const handleCloseModal: MuiModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return false
    }
    onCloseHandle()
  }

  const onCloseHandle = () => {
    setReason('')
    dispatch(
      fetchTenders({
        type: tab,
        options: {
          size: 20,
          page: 0,
          sort: { sortBy: 'sendDateTime', direction: 'desc' },
        },
      }),
    )
    onClose()
  }

  const tenderConditionsTextIsPlural =
    selectedIds.length > 1
      ? 'тендерных заданий/лотов'
      : 'тендерного задания/лота'

  return (
    <>
      <Modal
        title={'Удаление тендерного задания/лота'}
        onClose={handleCloseModal}
        onCloseIconButton={onCloseHandle}
        open={isModalOpen}
        footer={() => (
          <>
            <Button type='common' title='Отменить' onClick={onCloseHandle} />
            <Button
              type='secondary'
              title='Отменить ТЗ'
              disabled={!reason && tab !== TendersTabs.new}
              onClick={onConfirmRejectMail}
            />
          </>
        )}
      >
        <form className='form'>
          <div className='rejectModal'>
            <div className='rejectModalItem'>
              Вы уверены в отмене {tenderConditionsTextIsPlural}?
            </div>
            {tab === TendersTabs.sent && (
              <>
                <div className='rejectModalItem'>
                  Поставщикам отправится уведомление на почту и в личный
                  кабинет.
                </div>
                <div className='rejectModalItem'>
                  <b>Причина</b>
                </div>
                <div className='rejectModalItem'>
                  <RadioInput
                    onChange={(_, value) => {
                      setReason(value as string)
                    }}
                    value={reason}
                    options={[
                      {
                        label: 'Удаление тендерного задания/ лота',
                        value: 'Удаление лота',
                      },
                      {
                        label: 'Изменение тендерного задания/ лота',
                        value: 'Изменение лота',
                      },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      </Modal>
    </>
  )
}
