import { omit } from 'lodash'

import { TenderData, TSpecializations } from '../types/api'
import { ComboListItemType } from '../components/ui/combo-list/combo-list-item'

export const providersToComboListFormatter = (
  providers: TenderData['providers'],
): ComboListItemType[] =>
  providers?.map(provider => ({
    ...provider,
    key: provider.externalId,
    title: provider.name,
    value: provider.email,
  })) || []

export const comboListToProvidersFormatter = (
  list: ComboListItemType[],
): TenderData['providers'] =>
  list.map(item => {
    const resultItem = {
      ...item,
      name: item.title,
      email: item.value,
      externalId: item.key,
      id: item.id,
    }

    return omit(resultItem, ['title', 'value', 'key'])
  })

export const specializationsToComboListFormatter = (
  specs: TSpecializations[],
): ComboListItemType[] =>
  specs?.map(item => ({ key: item.id, value: item.name, title: item.name }))
