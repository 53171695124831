import React, { useCallback } from 'react'
import DefaultDrawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, ModalProps } from '@material-ui/core'

import './drawer.scss'

type DrawerProps = {
  open: boolean
  title: string
  onClose?: () => void
}

export const Drawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  children,
  title,
}) => {
  const handleCloseDrawer: ModalProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        return
      }

      if (onClose) {
        onClose()
      }
    },
    [onClose],
  )

  return (
    <DefaultDrawer
      className='custom-drawer'
      anchor='right'
      open={open}
      onClose={handleCloseDrawer}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      ref={null}
    >
      <div className='drawer-header'>
        <div className='title'>{title}</div>
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
      {children}
    </DefaultDrawer>
  )
}
