import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getProviderCategoriesSelector,
  providersParametersSelector,
} from 'src/store/providers/selectors'
import {
  getProviderCategories,
  getProviders,
} from 'src/store/providers/actions'
import { setFiltersProviders } from 'src/store/providers/providers-slice'

import { AppDispatch } from '../../store'
import { Catalog } from '../ui/catalog'
import { NestedCategory } from '../../types/api'

export const CatalogConnected: React.FC = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>()
  const categories = useSelector(getProviderCategoriesSelector)
  const providersParameters = useSelector(providersParametersSelector)

  const handleClickNode = (node: NestedCategory) => {
    if (node.type !== 'list') {
      return
    }

    const filter = {
      categoryId: node.id,
      keyword: '',
    }

    dispatch(
      setFiltersProviders({
        filter,
      }),
    )

    dispatch(
      getProviders({
        ...providersParameters,
        page: 0,
        filter,
      }),
    )
  }

  useEffect(() => {
    dispatch(getProviderCategories())
  }, [dispatch])

  return (
    <Catalog
      items={categories.data}
      isLoading={categories.isLoading}
      handleClickNode={handleClickNode}
    />
  )
})

CatalogConnected.displayName = 'CatalogConnected'
