import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Filters,
  Pagination,
  Provider,
  Sorting,
  NestedCategory,
} from 'src/types/api'
import { Key } from 'react'
import { union } from 'lodash'

import {
  getAllProvidersIds,
  getProviders,
  getProviderCategories,
} from './actions'

export type ProvidersState = Partial<Pagination> &
  Sorting &
  Filters & {
    isFetching: boolean
    totalElements: number
    data: Provider[]
    isEmpty: boolean
    selected: Key[]
    categories: {
      isLoading: boolean
      data: NestedCategory[]
    }
  }

const initialState: ProvidersState = {
  isFetching: false,
  totalElements: 0,
  data: [],
  isEmpty: false,
  selected: [],
  filter: {},
  categories: {
    isLoading: false,
    data: [],
  },
}

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelected: (state, action: PayloadAction<ProvidersState['selected']>) => {
      state.selected = action.payload
    },
    setParameters: (state, action: PayloadAction<Pagination & Sorting>) => ({
      ...state,
      ...action.payload,
    }),
    setFilters: (state, action: PayloadAction<Filters>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(getProviders.pending, state => {
      state.isFetching = true
      state.isEmpty = false
    })
    builder.addCase(getProviders.fulfilled, (state, action) => {
      state.data = action.payload.content
      state.totalElements = action.payload.totalElements
      state.isEmpty = action.payload.empty
      state.isFetching = false
    })
    builder.addCase(getProviders.rejected, state => {
      state.isFetching = false
    })

    builder.addCase(getAllProvidersIds.pending, state => {
      state.isFetching = true
    })
    builder.addCase(getAllProvidersIds.fulfilled, (state, action) => {
      state.selected = union(state.selected, action.payload)
      state.isFetching = false
    })
    builder.addCase(getAllProvidersIds.rejected, state => {
      state.isFetching = false
    })
    builder.addCase(getProviderCategories.pending, state => {
      state.categories.isLoading = true
    })
    builder.addCase(getProviderCategories.fulfilled, (state, { payload }) => {
      state.categories.data = payload
      state.categories.isLoading = false
    })
    builder.addCase(getProviderCategories.rejected, state => {
      state.categories.isLoading = false
    })
  },
})

export const {
  reset: resetProviders,
  setSelected: setSelectedProviders,
  setParameters: setParametersProviders,
  setFilters: setFiltersProviders,
} = providersSlice.actions

export default providersSlice.reducer
