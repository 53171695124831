import React from 'react'
import { Backdrop, BackdropProps, CircularProgress } from '@material-ui/core'
import classnames from 'classnames'

import './loader.scss'

type LoaderProps = BackdropProps & {
  open: boolean
  className?: string
  iconSize?: number | string
}

export const Loader: React.FC<LoaderProps> = ({
  open,
  className = '',
  iconSize = 30,
  ...restProps
}) => (
  <Backdrop
    {...restProps}
    className={classnames('loader-with-backdrop', className)}
    open={open}
  >
    <CircularProgress color='inherit' size={iconSize} />
  </Backdrop>
)
