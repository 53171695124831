import React from 'react'
import cn from 'classnames'

import { NavLink } from 'react-router-dom'

type SideMenuLinkProps = {
  to: string
  className?: string
}

export const HeaderLink: React.FC<SideMenuLinkProps> = ({
  to,
  className,
  children,
}) => (
  <NavLink to={to} className={cn('side-menu-link', className)}>
    {children}
  </NavLink>
)
