import { TendersTabs } from 'src/constants/routes'

import { Key } from './common'

export type TypeTenders = TendersTabs

export enum ModuleTypes {
  grandtender = 'grandtender',
  tender = 'tender',
}

export type UploadedFile = {
  id: number
  name: string
  url: string
  isRequired?: boolean
  lotIndex?: number
}

export type Provider = {
  name: string
  email: string
  externalId: Key
  id: Key
  sbConclusion?: string
  sbConclusionDate?: string
  specialisations?: string[]
  kpp?: string
  inn?: string
}

export type TSpecializations = {
  id: number
  name: string
}

export type Lot = {
  lotId: number
  lotName: string
  specializations: TSpecializations[]
  attachments: UploadedFile[]
  providers: Provider[]
}

export type TenderData = {
  id: string
  tenderTaskNumber: string
  lotName: string
  lotId?: string
  subject: string | null
  text?: string | null
  customer?: string | null
  contacts?: string | null
  email?: string | null
  specializations?: TSpecializations[]
  isPublic?: boolean
  tenderType?: string
  dateEnd?: string | number | null
  dateStart?: string | number | null
  providers?: Provider[]
  attachments: UploadedFile[]
  sendToFsk?: boolean
  module?: ModuleTypes
  sendDateTime?: string
  countOfProviders?: number
  countOfAttachments?: number
  lots: Lot[]
}

export type Pagination = {
  page: number
  size: number
}

export type Sorting = {
  sort?: {
    sortBy?: string
    direction?: 'asc' | 'desc'
  }
}

export type Filters = {
  filter?: {
    categoryId?: number
    keyword?: string
  }
}

export type WithOptions<T> = {
  content: T
  totalElements: number
  number: number
  size: number
  totalPages: number
  empty: boolean
}

export type Category = {
  id: number
  name: string
  parentId?: number
  serialNumber: number
  type: string
}

export type NestedCategory = Category & {
  children?: NestedCategory[]
}

export type IncomingMailFile = {
  bucket: null
  fileName: string
  id: number
  isRequired?: boolean
  key: null
  name: string
  size: null
  url: string
  status: string
}

export type IncomingMailProvider = {
  id: number
  externalId: number
  name: string
  email: string
}

export type IncomingMail = {
  createDateTime: string
  files: IncomingMailFile[]
  from: string
  id: number
  provider: IncomingMailProvider
  subject: string
  text: string
  to: string
}

export type OutgoingMail = {
  attachments: IncomingMailFile[]
  contacts: string
  customer: string
  dateEnd: string
  dateStart: string
  email: string
  incomingMails: IncomingMail[]
  isPublic: boolean
  lotId: number
  lotName: string
  module: string
  providers: Provider[]
  specializations: TSpecializations[]
  subject: string
  tenderTaskNumber: number
  tenderType: string
  text: string
  sendDateTime: string
}

export type RejectMail = {
  mailIds: Key[]
  reason?: string
}

export type User = {
  company: string
  fullName: string
  inn: string
  kpp: string
  role: string
  username: string
}
