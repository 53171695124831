import { RootState } from 'src/store'
import { TenderState } from 'src/store/tender/tender-slice'

export const tenderDataSelector = (state: RootState): TenderState['data'] =>
  state.tender.data

export const tenderFetchStatusSelector = (
  state: RootState,
): TenderState['isModalFetching'] => state.tender.isModalFetching

export const tenderFilesUploadingStatusSelector = (
  state: RootState,
): TenderState['isFilesUploading'] => state.tender.isFilesUploading
