import React from 'react'
import { Button as MuiButton, ButtonTypeMap } from '@material-ui/core'
import classnames from 'classnames'

import './button.scss'

export type CustomButtonProps = ButtonTypeMap<{
  type?: 'primary' | 'secondary' | 'warning' | 'common'
  title: string
  onClick?(event?: React.MouseEvent<HTMLElement>): void
}>['props']

export const Button: React.FC<CustomButtonProps> = ({
  title,
  type = 'primary',
  onClick,
  ...rest
}) => (
  <MuiButton
    className={classnames('customButton', type)}
    onClick={onClick}
    {...rest}
  >
    {title}
  </MuiButton>
)
