import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import ReduxToastr from 'react-redux-toastr'

import {
  AppRoutes,
  TENDERS_START_PATH,
  REDIRECT_TO_TENDERS_PATHS,
} from 'src/constants/routes'

import NotFound from 'src/pages/not-found/not-found'
import Tenders from 'src/pages/tenders/tenders'
import Specs from 'src/pages/specs/specs'
import TenderDetail from 'src/pages/tender-detail/tender-detail'
import { getMaintenance } from 'src/store/maintenance/maintenance-selectors'
import { Maintenance } from '@gmini/ui-kit'

import './styles/index.scss'

import { useSelector } from 'react-redux'

export const App: React.FC = () => {
  const maintenanceEnabled = useSelector(getMaintenance)

  if (maintenanceEnabled) {
    return <Maintenance />
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={REDIRECT_TO_TENDERS_PATHS}>
          <Redirect to={TENDERS_START_PATH} />
        </Route>
        <Route path={AppRoutes.tenderDetail} component={TenderDetail} />
        <Route path={AppRoutes.tenders} component={Tenders} />
        <Route path={AppRoutes.specs} component={Specs} />
        <Route component={NotFound} />
      </Switch>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        preventDuplicates={false}
        position='top-center'
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        progressBar={false}
        closeOnToastrClick
      />
    </BrowserRouter>
  )
}
