import React from 'react'
import classnames from 'classnames'

import {
  FileUploadItem,
  FileUploadItemType,
} from '../file-upload/file-upload-item'

import './file-list.scss'

export type FileListProps = {
  value?: FileUploadItemType[]
  onDelete?: (file: FileUploadItemType) => void
  className?: string
}

export const FileList: React.FC<FileListProps> = ({
  value,
  onDelete,
  className,
}) => (
  <div className={classnames('file-list', className)}>
    {value?.map(({ name, url, id, fileStatus, ...rest }) => (
      <FileUploadItem
        id={id}
        name={name}
        url={url}
        {...rest}
        key={`${name}-${url}`}
        onDelete={onDelete}
        fileStatus={fileStatus}
      />
    ))}
  </div>
)
