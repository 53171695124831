import styled, { css } from 'styled-components'

type ModulesNavButtonProps = {
  hover?: boolean
}

export const ModulesNavButton = styled.div`
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);

  &:hover {
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    path {
      fill: #4c5ecf;
    }
  }

  path {
    fill: #fff;
  }

  ${(props: ModulesNavButtonProps) =>
    props.hover &&
    css`
      background: rgba(255, 255, 255, 1);
      path {
        fill: #4c5ecf;
      }
    `}
`

export const ModulesNavPopover = styled.div`
  width: 207px;
  position: absolute;
  right: -50px;
  top: calc(100% + 4px);
  box-shadow: 0px 5px 10px rgba(53, 60, 96, 0.15);
  border-radius: 4px;
  padding: 4px 0;
  z-index: 10000;
  background: #fff;
  & a {
    text-decoration: none;
  }
`

export const ModulesNavPopoverDivider = styled.div`
  height: 1px;
  background: #e5e7f1;
  margin: 2px 12px;
`

export const ModulesNavPopoverItem = styled.div`
  padding: 9.5px 12px;
  text-transform: uppercase;
  color: #00003d;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    background: rgba(0, 5, 100, 0.04);
  }
`

export const NavbarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &:hover ${ModulesNavButton} {
    background: rgba(255, 255, 255, 1);
  }
`
