import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'

import PersonAddIcon from '@material-ui/icons/PersonAdd'

import { Accordion } from '@ifellow/ui-library'

import { IncomingMail, IncomingMailFile } from 'src/types/api'

import { AppDispatch } from 'src/store'
import {
  getIncomingMail,
  getTenderDetailSelector,
  resetTenderDetail,
} from 'src/store/tender-detail'

import {
  providersToComboListFormatter,
  specializationsToComboListFormatter,
} from 'src/utils/combo-list'

import { withPage } from 'src/components/HOC/with-page/with-page'
import { Loader } from 'src/components/ui/loader/loader'
import { FormLabel } from 'src/components/ui/form-label/form-label'
import { ComboList } from 'src/components/ui/combo-list/combo-list'
import { FileList } from 'src/components/ui/file-list/file-list'
import { FileUploadItemStatus } from 'src/components/ui/file-upload/file-upload-item'

import { Header } from './components/header/header'
import { Content } from './components/content/content'
import { DetailMail } from './components/detail-mail/detail-mail'

import './tender-detail.scss'

const getFileStatus = (status: string): FileUploadItemStatus | undefined => {
  switch (status) {
    case 'FAILURE':
      return FileUploadItemStatus.Error
    case 'SUCCESS':
      return FileUploadItemStatus.Success
    case 'INOPERABLE':
      return FileUploadItemStatus.Warning
  }
}

const TenderDetail: React.FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const { isLoading, data: detailMailData } = useSelector(
    getTenderDetailSelector,
  )
  const attachments: IncomingMailFile[] = get(detailMailData, 'attachments', [])
  const incomingMails: IncomingMail[] = get(detailMailData, 'incomingMails', [])

  useEffect(() => {
    dispatch(getIncomingMail(id))
    return () => {
      dispatch(resetTenderDetail())
    }
  }, [dispatch, id])

  return (
    <>
      <Loader open={isLoading} />
      <Header
        tenderTaskNumber={detailMailData.tenderTaskNumber}
        lotName={detailMailData.lotName}
        sendDateTime={detailMailData.sendDateTime}
      />
      <Content>
        <DetailMail
          customer={detailMailData.customer}
          tenderType={detailMailData.tenderType}
          dateStart={detailMailData.dateStart}
          dateEnd={detailMailData.dateEnd}
          contacts={detailMailData.contacts}
          email={detailMailData.email}
          text={detailMailData.text}
        />
        <ComboList
          className={'detail-combo-list'}
          label='Список контрагентов'
          addIcon={PersonAddIcon}
          value={providersToComboListFormatter(detailMailData.providers)}
          disabled={true}
        />
        <ComboList
          className={'detail-combo-list'}
          label='Специализация'
          value={specializationsToComboListFormatter(
            detailMailData.specializations || [],
          )}
          disabled={true}
        />
        <FormLabel label='Документы' />
        <FileList className={'detail-combo-list'} value={attachments} />
        {incomingMails.map((el: IncomingMail) => (
          <Accordion key={el.id} title={el.provider.name}>
            <div className='detail-page-accordion-detail'>
              <FormLabel label='Документы' />
              {el.files && (
                <FileList
                  value={el.files.map(el => ({
                    ...el,
                    name: el.fileName,
                    isRequired: true,
                    fileStatus: getFileStatus(el.status),
                  }))}
                />
              )}
            </div>
          </Accordion>
        ))}
      </Content>
    </>
  )
}

export default withPage(TenderDetail)
