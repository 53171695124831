import { createSlice } from '@reduxjs/toolkit'

import { User } from 'src/types/api'

import { getUser } from './actions'

const initialState: User = {
  company: '',
  fullName: '',
  inn: '',
  kpp: '',
  role: '',
  username: '',
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUser.fulfilled, (state, action) => action.payload.data)
  },
})

export default user.reducer
