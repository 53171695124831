import React from 'react'

import { Header } from './header'

import './with-page.scss'

export const withPage =
  (WrappedComponent: React.ComponentType): React.FC =>
  // eslint-disable-next-line react/display-name
  props =>
    (
      <div className='page-container'>
        <Header />
        <div className='content'>
          <WrappedComponent {...props} />
        </div>
      </div>
    )
