import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  OutgoingMail,
  Pagination,
  Sorting,
  TenderData,
  TypeTenders,
  WithOptions,
} from 'src/types/api'

import { Key } from '../../types/common'
import { apiClient } from '../../services/ApiService'

export const getTenders = createAsyncThunk<
  WithOptions<TenderData[]>,
  { type: TypeTenders; options: Pagination & Sorting }
>('tender/getTenders', async ({ type, options }, { rejectWithValue }) => {
  const tenderActions = {
    new: 'outgoing_mail/new',
    sent: 'outgoing_mail/sent',
    incoming: 'outgoing_mail/answered',
  }
  const [err, res] = await apiClient.post<any, WithOptions<TenderData[]>>({
    path: `/${tenderActions[type]}`,
    body: options,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data
})

export const getAllTendersIds = createAsyncThunk<Key[], { type: TypeTenders }>(
  'tender/getAllTendersIds',
  async ({ type }, { rejectWithValue }) => {
    const tenderActions = {
      new: 'outgoing_mail/new',
      sent: 'outgoing_mail/sent',
      incoming: 'outgoing_mail/answered',
    }
    const [err, res] = await apiClient.get<Key[]>({
      path: `/${tenderActions[type]}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const getTender = createAsyncThunk<TenderData, { source: Key; id: Key }>(
  'tender/getTender',
  async ({ source, id }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<TenderData>({
      path: `/publication/${source}/tender-task/${id}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const getTenderLot = createAsyncThunk<
  TenderData,
  { source: Key; id: Key }
>('tender/getTenderLot', async ({ source, id }, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<TenderData>({
    path: `/publication/${source}/${id}`,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data
})

export const getIncomingMail = createAsyncThunk<OutgoingMail, Key>(
  'tender/getIncomingMail',
  async (id, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<OutgoingMail>({
      path: `/outgoing_mail/mails/${id}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)
