import { PROVIDER_DEFAULT_PAGE_SIZE } from 'src/constants/providers'
import { RootState } from 'src/store'
import { Filters, Pagination, Sorting } from 'src/types/api'
import { createSelector } from '@reduxjs/toolkit'

import { ProvidersState } from './providers-slice'

export const providersStateSelector = (state: RootState): ProvidersState =>
  state.providers

export const providersParametersSelector = (
  state: RootState,
): Pagination & Sorting => ({
  page: state.providers?.page || 0,
  size: state.providers.size || PROVIDER_DEFAULT_PAGE_SIZE,
  sort: state.providers.sort,
})

export const providersFilterSelector = (
  state: RootState,
): Required<Filters>['filter'] => ({
  keyword: state.providers.filter?.keyword || '',
})

export const getProviderCategoriesSelector = createSelector(
  providersStateSelector,
  providers => providers.categories,
)
