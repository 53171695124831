import React, { useCallback, useEffect, useState } from 'react'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core'

import './radio-input.scss'

type PropOption = {
  label: string
  value: unknown
}

type Option = {
  label: string
  value: string
}

type RadioInputProps = Omit<RadioGroupProps, 'onChange'> & {
  options: PropOption[]
  onChange: (event: React.ChangeEvent, value: unknown) => void
}

export const RadioInput: React.FC<RadioInputProps> = ({
  options: propOptions,
  onChange,
  value,
  ...props
}) => {
  const [options, setOptions] = useState<Option[]>([])

  const handleOnChange = useCallback<Required<RadioGroupProps>['onChange']>(
    (event, value) => {
      if (onChange) {
        onChange(event, JSON.parse(value))
      }
    },
    [onChange],
  )

  useEffect(() => {
    setOptions(
      propOptions.map(({ value, label }) => ({
        label,
        value: JSON.stringify(value),
      })),
    )
  }, [propOptions])

  return (
    <RadioGroup
      className='mailer-radio-input'
      onChange={handleOnChange}
      value={JSON.stringify(value)}
      row
      {...props}
    >
      {options.map(({ label, value }) => (
        <FormControlLabel
          key={`${label}-${value}`}
          value={value}
          control={<Radio color='primary' />}
          label={label}
        />
      ))}
    </RadioGroup>
  )
}
